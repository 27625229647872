import useUsers from "../../../context/useUsers"
import dayjs from "dayjs"
import LoadingSpinner from "../../../helpers/LoadingSpinner"
import { useTranslation } from "react-i18next"

const UserList = (s) => {
  const { loading, users } = useUsers()
  const { t } = useTranslation()

  // Count admins and ordinary users
  const userCounts = Object.values(users).reduce(
    (acc, user) => {
      acc[user.userType === "admin" ? "admins" : "ordinary"]++;
      return acc;
    },
    { admins: 0, ordinary: 0 }
  );
  const usersTable = (
    <div className="box">

      <div className="columns is-centered">
        <div className="column is-narrow">
          <p><b>{t("users.counter.admins")}</b>: {userCounts.admins}</p>
        </div>
        <div className="column is-narrow">
          <p> <b>{t("users.counter.ordinary")}</b>: {userCounts.ordinary}</p>
        </div>
      </div>

      <div className="table-container">
        <table className="table is-narrow">
          <thead>
            <tr>
              <th>{t("users.user")}</th>
              <th>{t("users.role")}</th>
              <th>{t("users.position")}</th>
              <th>{t("users.boss")}</th>
              <th>{t("users.created")}</th>
              <th>{t("users.lastSeen")}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(users).map(id => (
              <>
                <tr >
                  <td>
                    <p>{users[id]?.fullName}</p>
                    <p>{users[id]?.email}</p>
                  </td>
                  <td>
                    <p>{users[id]?.userType === "ordinary" ? "User" : users[id]?.userType === "admin" ? "Admin" : users[id]?.userType}</p>
                  </td>
                  <td>
                    <p>{users[id]?.company}</p>
                    <p>{users[id]?.position}</p>
                  </td>
                  <td>
                    <p>{users[id]?.bossFullName}</p>
                    <p>{users[id]?.bossEmail}</p>
                  </td>

                  <td>
                    <p>{users[id]?.creationDate ? dayjs(users[id]?.creationDate).format("DD.MM.YYYY - HH:mm") : ""}</p>
                  </td>

                  <td>
                    <p>{users[id]?.lastSeenDate ? dayjs(users[id]?.lastSeenDate).format("DD.MM.YYYY - HH:mm") : ""}</p>
                  </td>
                </tr>
                {/* <tr>
                  <td>{ }</td>
                  <td>{users[id].bossEmail}</td>
                  <td>
                    {console.log(users[id]?.lastSeenDate)}
                    {users[id]?.lastSeenDate ? dayjs(users[id]?.lastSeenDate).format("DD/MM/YYYY - HH:mm") : ""}
                  </td>
                </tr> */}

              </>


            ))}



          </tbody>
        </table>
      </div>
    </div >
  )

  return loading ? <LoadingSpinner /> : usersTable
}

export default UserList
