import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import axios from "axios"
import { handleError } from "../errors"


const useUploadTasks = () => {

    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(true)
    const [tasks, setTasks] = useState({})

    const [taskType, setTaskType] = useState("manual")


    const fetchUploadTasks = async () => {

        try {
            const resp = await axios.get(
                `${PYTHON_API_URL}/restricted/statistics/upload/task/list?taskType=${taskType}`
            )

            const { tasks } = resp.data
            setTasks(tasks.reduce((acc, current, index) => {
                acc[current["id"]] = current;
                return acc;
            }, {}))
            setLoading(false)

        } catch (error) {
            setLoading(false)

            return handleError(error)

        }


    }

    useEffect(() => {
        setLoading(true);
        fetchUploadTasks();
        setNeedRefetch(false);
        // eslint-disable-next-line
    }, [needRefetch, taskType]);

    useEffect(() => {
        const conditionalFetch = async () => {
            const requestCondition = Object.values(tasks).some(item => item.status === 'active');
            if (requestCondition) {
                await fetchUploadTasks();
            }
        };

        const intervalId = setInterval(conditionalFetch, 5 * 1000);

        return () => clearInterval(intervalId);
    }, [tasks]);

    return { loading, tasks, setNeedRefetch, taskType, setTaskType }

}

export default useUploadTasks