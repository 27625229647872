import React from "react";
const DraggableRow = ({ id, title, handleDrag, handleDrop, isBlocked }) => {

    return (

        <div className="field">
            <div className="control">
                <div className="columns is-vcentered">
                    <div className="column">
                        <div
                            className={`button is-fullwidth`}
                            disabled={isBlocked}
                            draggable={true}
                            id={id}
                            onDragOver={(ev) => ev.preventDefault()}
                            onDragStart={handleDrag}
                            onDrop={handleDrop}
                        >
                            {title}
                        </div>
                    </div>
                </div>


            </div>
        </div>

    );
};

export default DraggableRow;
