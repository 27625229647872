import { useContext, useState } from "react"
import { SelectionContext } from "../../../../../context/SelectionContext"
import { useTranslation } from "react-i18next"
import Selector from "../../../../../helpers/Selector"
import i18n from "../../../../../i18n"

const FieldsSection = () => {
    const { t } = useTranslation()

    const [maxOrder, setMaxOrder] = useState(null)

    const {

        fields,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

        fieldsValues,
        loadingFieldsValues,


    } = useContext(SelectionContext)

    const [selectorValues, setSelectorValues] = useState({})

    const activateField = field => {
        const updatedSelectedFields = Object.keys(selectedFields).reduce((acc, key) => {
            acc[key] = { ...selectedFields[key], isActive: key === field.nameData };
            return acc;
        }, {});

        return updatedSelectedFields


    }

    const updateSelectedField = (field, targets, updateAllSelected) => {

        // Assigning values to currently updated values
        setSelectorValues(selectorValues => ({ ...selectorValues, [field.id]: targets }))

        // Getting raw values of currently updated values
        const curSelectorValues = targets.map(item => item.value);

        // Disable other fields which are not selected now
        const updatedSelectedFields = activateField(field)

        var isAllSelected = false
        if (field.nameData in updatedSelectedFields) {
            isAllSelected = updatedSelectedFields[field.nameData].isAllSelected
        }

        isAllSelected = updateAllSelected ? !isAllSelected : false




        // Adding current field to 
        updatedSelectedFields[field.nameData] = {
            field: field.nameData,
            isActive: true,
            isAlwaysSelected: field.isAlwaysSelected,
            isUnmerged: field.isUnmerged,
            values: updateAllSelected ? [] : curSelectorValues,
            isAllSelected: isAllSelected,

        };

        const updatedSelectedFieldsOrder = selectedFieldsOrder


        // Checking if field is in order list
        const fieldOrder = updatedSelectedFieldsOrder.find(item => item.id === field.id);

        if ((curSelectorValues.length > 0) || (updateAllSelected && isAllSelected)) {
            // If it's not present in order list, and not hidden, push to order
            if (!fieldOrder && !field.isHiddenInOrder) {
                updatedSelectedFieldsOrder.push({
                    id: field.id,
                    field: field.nameData,
                    isAlwaysSelected: field.isAlwaysSelected,
                    isHiddenInOrder: field.isHiddenInOrder,
                    isUnmerged: field.isUnmerged,
                    order: maxOrder + 1,
                    isBlocked: false,
                    title: i18n.language === "en" ? field.nameEn : field.nameRu,

                });
            }

            setSelectedFieldsOrder(updatedSelectedFieldsOrder);
            setMaxOrder(maxOrder + 1)
        } else {

            if (!field?.isAlwaysSelected && !updateAllSelected) {
                delete updatedSelectedFields[field.nameData];
            }


            setSelectedFieldsOrder(
                updatedSelectedFieldsOrder.filter(item => (item.id !== field.id) || item.isAlwaysSelected)
            );

        }


        setSelectedFields(updatedSelectedFields);

    }



    const createField = field => {

        const isFieldAllSelected = selectedFields?.[field?.nameData]?.isAllSelected

        return (
            <div className="columns" key={field.nameData}>
                <div className="column is-one-quarter">
                    <div className="label has-text-centered">{
                        i18n.language === "en" ?
                            field.nameEn :
                            field.nameRu
                    }</div>
                </div>
                <div className="column is-narrow">
                    <i className="fa fa-question fa-lg" title={
                        i18n.language === "en" ?
                            field.descriptionEn :
                            field.descriptionRu
                    }></i>
                </div>
                <div className="column">

                    <div className="field has-text-centered">
                        <div className="control">


                            <Selector
                                isMulti={true}
                                subKey={field.id}
                                isSearchable={true}
                                isDisabled={isFieldAllSelected}
                                value={selectorValues?.[field.id]}
                                isOptionDisabled={() => loadingFieldsValues}
                                placeholder={isFieldAllSelected ? t("series.allSelectedPlaceholder") : ""}
                                options={fieldsValues?.[field.id]?.map(
                                    value => (
                                        {
                                            value: value,
                                            label: value

                                        }
                                    )
                                )}

                                onMenuOpen={() => {
                                    // Activating current field and disabling others
                                    const updatedFiedls = activateField(field)
                                    setSelectedFields(updatedFiedls);
                                }}

                                onChange={targets => updateSelectedField(field, targets, false)}

                                isLoading={loadingFieldsValues}

                            />
                        </div>
                    </div>
                </div>
                <div className="column is-narrow">
                    <button
                        className={`button ${isFieldAllSelected ? "is-success" : ""}`}
                        disabled={(selectorValues?.[field.id]?.length > 0) || loadingFieldsValues}
                        onClick={
                            () => updateSelectedField(field, [], true)
                        }
                    >
                        {t("series.selectAll")}
                    </button>
                </div>
            </div>
        )

    }


    return (
        <div className="section">

            <div className="content has-text-centered">
                <h3>{t("series.selection")}</h3>
            </div>
            {
                fields?.filter(item => !item?.isHiddenInSearch)?.map(
                    field => createField(field)
                )
            }
        </div>
    )
}

export default FieldsSection