import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"
import i18n from "../i18n"

const useModes = () => {
    const [modes, setModes] = useState([])

    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(false)

    const fetchModes = async () => {
        try {
            const resp = await axios.get(`${PYTHON_API_URL}/restricted/mode/list`)

            const { modes: modesList } = resp.data

            setModes(modesList?.map(
                mode => (
                    {
                        value: mode.id,
                        label: i18n.language === "en" ?
                            mode.nameEn :
                            mode.nameRu,
                        ...mode
                    }
                )
            ))
            setLoading(false)
            setNeedRefetch(false)
        } catch (error) {
            setLoading(false)
            return handleError(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchModes()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (needRefetch) {
            setLoading(true)
            fetchModes()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return { loading, modes, setNeedRefetch }
}

export default useModes
