import { useContext } from "react";
import { NewsContext } from "../../../context/NewsContext";
import Pagination from "../../../helpers/Pagination";
import News from "./News";


const NewsList = () => {
    const { news, pageCount, currentPage, setCurrentPage } = useContext(NewsContext);

    return (

        news?.length === 0 ? null :
            <>

                <Pagination
                    pageCount={pageCount}
                    page={currentPage}
                    setPage={setCurrentPage}
                />

                <br />



                {news?.length === 0 ? null :
                    <div className="message-list">
                        {news?.map((message) => (
                            <News key={message.id} message={message} />
                        ))}
                    </div>}
            </>
    );
}

export default NewsList