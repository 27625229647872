
import LoadingSpinner from "../../../../helpers/LoadingSpinner"
import { useContext } from "react"
import { SeriesContext } from "../../../../context/SeriesContext"

import "react-datepicker/dist/react-datepicker.css";
import { SelectionContext } from "../../../../context/SelectionContext"
import GroupSelector from "./GroupSelector"
import GroupFilters from "./GroupFilters"



const DataSelection = () => {


    const {

        loadingGroups,

    } = useContext(SeriesContext)

    const {
        group,

    } = useContext(SelectionContext)


    return (
        <>


            <GroupSelector />

            {
                loadingGroups ?
                    <LoadingSpinner /> :

                    (group === null ? null : <GroupFilters />)
            }

        </>
    )
}

export default DataSelection