import axios from "axios"
import { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { PYTHON_API_URL, isStrongPwd, useQuery } from "../utils"
import LoadingSpinner from "../helpers/LoadingSpinner"
import { errorMessage, handleError } from "../errors"
import { useTranslation } from "react-i18next"
import PasswordRules from "./PasswordRules"
import LangAuthSelector from "./LangAuthSelector"

const ResetPassword = () => {
  const query = useQuery()
  const history = useHistory()

  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")

  const [redPassword, setRedPassword] = useState(false)
  const [redPasswordRepeat, setRedPasswordRepeat] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [loading, setLoading] = useState(true)
  const [codeValid, setCodeValid] = useState(false)
  const { t } = useTranslation()

  const checkReset = async () => {

    try {
      await axios.get(
        `${PYTHON_API_URL}/unrestricted/auth/reset/check?code=${query.get("code")}`
      )

      setCodeValid(true)
      setLoading(false)
    }
    catch (error) {
      setCodeValid(false)
      setLoading(false)

      handleError(error)

    }

  }

  useEffect(
    () => checkReset(),
    // eslint-disable-next-line
    []
  )

  const resetPassword = async () => {
    if (password === "") {
      setRedPassword(true)
      return toast.error(errorMessage("su:::000"))
    }

    if (passwordRepeat === "") {
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::001"))
    }

    if (!isStrongPwd(password)) {
      setRedPassword(true)
      setRedPasswordRepeat(true)

      return toast.error(errorMessage("su:::002"))
    }

    if (password !== passwordRepeat) {
      setRedPassword(true)
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::003"))
    }

    setSubmitting(true)
    try {
      const data = {
        password,
        code: query.get("code")
      }

      await axios.post(`${PYTHON_API_URL}/unrestricted/auth/reset`, data)

      setSubmitting(false)
      history.push(`/auth`)


    } catch (error) {
      setSubmitting(false)
      if (error?.response) {
        const { message } = error?.response?.data
        toast.error(errorMessage(message))
      } else {
        toast.error(errorMessage())
      }
      return
    }


    toast.success(t("resetPassword.success"))
  }

  return (
    <div className="section">
      <div className="container has-text-centered">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="columns is-centered">
            <div className="column is-5">
              {codeValid ? (
                <form className="box">
                  <div className="title is-6">
                    {t("resetPassword.insertNew")}
                  </div>
                  <div className="field">
                    <label className="label has-text-left">
                      {t("signUp.password")}
                    </label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${redPassword ? "is-danger" : ""}`}
                        type="password"
                        value={password}
                        placeholder="********"
                        onChange={e => setPassword(e.target.value)}
                        onFocus={() => setRedPassword(false)}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label has-text-left">
                      {t("signUp.passwordRep")}
                    </label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${redPasswordRepeat ? "is-danger" : ""
                          }`}
                        type="password"
                        value={passwordRepeat}
                        placeholder="********"
                        required
                        onChange={e => setPasswordRepeat(e.target.value)}
                        onFocus={e => setRedPasswordRepeat(false)}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <PasswordRules />

                  <div className="field">
                    <div className="control">
                      <div
                        className={`button ${submitting ? "is-loading" : ""
                          } is-dark`}
                        type="submit"
                        onClick={() => resetPassword()}
                      >
                        {t("resetPassword.resetSubmit")}
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="notification has-text-centered">
                  <div className="content">{t("resetPassword.outdated")}</div>
                </div>
              )}
              <hr />
              <LangAuthSelector />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResetPassword
