
import NewsCreate from "./NewsCreate";
import NewsList from "./NewsLits";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";




const NewsView = () => {

    const {
        user: { userType }
    } = useContext(UserContext)

    return (
        <>

            {userType === "admin" ? <NewsCreate /> : null}
            <NewsList />
        </>
    )

}

export default NewsView
