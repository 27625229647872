
import axios from "axios";
import { useState } from "react";
import { PYTHON_API_URL, reportsOptions } from "../../../utils";
import { toast } from "react-toastify";
import { errorMessage, handleError } from "../../../errors";
import fileDownload from "js-file-download";
import Selector from "../../../helpers/Selector";
import i18n from "../../../i18n"





const ReportsView = () => {

  const [submitting, setSubmitting] = useState(false)
  const [report, setReport] = useState("")
  const [redReport, setRedReport] = useState(false)

  const getReport = async () => {

    if (report === "") {
      setRedReport(true)
      return toast.error(errorMessage("rp:::000"))
    }


    setSubmitting(true)
    try {
      const config = {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
      const resp = await axios.get(`${PYTHON_API_URL}/restricted/reports/${report}`, config)


      setSubmitting(false)
      fileDownload(resp.data, `report.xlsx`)
    } catch (error) {
      setSubmitting(false)
      return handleError(error)
    }
    setSubmitting(false)
  }

  return (

    <div className="columns is-centered">
      <div className="column">
        <Selector
          options={reportsOptions[i18n?.language]}
          onChange={target => setReport(target.value)}
          onFocus={() => setRedReport(false)}
          isRed={redReport}
        />
      </div>
      <div className="column">

        <div className={`button is-link ${submitting ? "is-loading" : ""}`} onClick={() => getReport()}>
          Download report
        </div>
      </div>
    </div>

  )


}

export default ReportsView
