import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"

const useFrequencies = () => {
    const [frequencies, setFrequencies] = useState([])

    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(false)

    const fetchFrequencies = async () => {
        try {
            const resp = await axios.get(`${PYTHON_API_URL}/restricted/frequency/list`)

            const { frequencies: frequenciesList } = resp.data


            setFrequencies(frequenciesList?.reduce((result, frequency) => {
                result[frequency.name] = frequency.order;
                return result;
            }, {}))
            setLoading(false)
            setNeedRefetch(false)
        } catch (error) {
            setLoading(false)
            handleError(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchFrequencies()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (needRefetch) {
            setLoading(true)
            fetchFrequencies()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return { loading, frequencies, setNeedRefetch }
}

export default useFrequencies
