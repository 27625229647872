import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"

const useUsers = () => {
  const [users, setUsers] = useState({})
  const [loading, setLoading] = useState(false)
  const [needRefetch, setNeedRefetch] = useState(false)

  const fetchUsers = async () => {
    try {
      const resp = await axios.get(`${PYTHON_API_URL}/restricted/user/list`)

      const { users: usersList } = resp.data

      const usersObj = {}

      for (const item of usersList) {
        usersObj[item.id] = item
      }

      setUsers(usersObj)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      return handleError(error)

    }

  }

  useEffect(() => {
    setLoading(true)
    fetchUsers()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (needRefetch) {
      setLoading(true)
      fetchUsers()
    }
    // eslint-disable-next-line
  }, [needRefetch])

  return { loading, users, setNeedRefetch }
}

export default useUsers
