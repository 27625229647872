import { createContext } from "react"
import useSelection from "./useSelection"

export const SelectionContext = createContext()

const SelectionContextProvider = ({ children }) => {


    const {
        fields,
        loadingFields,
        setNeedRefetchFields,

        updatedFields,
        setUpdatedFields,


        fieldsValues,
        loadingFieldsValues,
        setNeedRefetchFieldsValues,

        group,
        setGroup,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

        lastUpdateDate,
        setLastUpdateDate,

        frequencyCount,

        selectedFrequencies,
        setSelectedFrequencies,

        outputFormat,
        setOutputFormat,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        includePreliminary,
        setIncludePreliminary,

        isDownloadEmpty,
        setIsDownloadEmpty,

        selectDateColumnRed,
        setSelectDateColumnRed,

        selectedDateColumns,
        setSelectedDateColumns

    } = useSelection()



    const value = {
        group,
        setGroup,

        fields,
        loadingFields,
        setNeedRefetchFields,

        updatedFields,
        setUpdatedFields,

        fieldsValues,
        loadingFieldsValues,
        setNeedRefetchFieldsValues,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

        lastUpdateDate,
        setLastUpdateDate,

        frequencyCount,


        selectedFrequencies,
        setSelectedFrequencies,

        outputFormat,
        setOutputFormat,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        includePreliminary,
        setIncludePreliminary,

        isDownloadEmpty,
        setIsDownloadEmpty,

        selectDateColumnRed,
        setSelectDateColumnRed,

        selectedDateColumns,
        setSelectedDateColumns

    }

    return (
        <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>
    )
}

export default SelectionContextProvider
