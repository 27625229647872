import InviteList from "./InviteList"
import { userTypesOptions } from "../../../utils"
import { inviteStatusOptions } from "../../../utils"
import Selector from "../../../helpers/Selector"
import i18n from "../../../i18n"
import { InvitesContext } from "../../../context/InvitesContext"
import { useContext } from "react"

const InviteListView = () => {
  const { setUserType, setIsActive } = useContext(InvitesContext)

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-12">
          <div className="columns is-centered">
            <div className="column is-4">
              <Selector
                options={userTypesOptions[i18n?.language]}
                defaultValue={userTypesOptions[i18n?.language]?.[0]}
                onChange={target => setUserType(target.value)}
              />
            </div>
            <div className="column is-4">
              <Selector
                options={inviteStatusOptions[i18n?.language]}
                defaultValue={inviteStatusOptions[i18n?.language]}
                onChange={target => setIsActive(target.value)}
              />
            </div>
          </div>
          <InviteList />
        </div>
      </div>
    </>
  )
}

export default InviteListView
