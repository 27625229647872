import { textEn } from "./en";
import { textRu } from "./ru";
import { useTranslation } from "react-i18next";




const MainView = () => {

  const { i18n } = useTranslation()

  return (
    i18n?.language === "en" ? textEn : textRu
  );

}

export default MainView
