import _ from "lodash"
import Select from "react-select"
const Selector = ({
  options,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onMenuOpen,
  menuIsOpen,
  value,
  label,
  subKey,
  isOptionDisabled,
  isDisabled = false,
  isLoading = false,
  isRed = false,
  placeholder = "",
  styles = {},
  isSearchable = false,
  isClearable = false,
  isMulti = false,
}) => {
  const defaultStyles = {
    control: styles => ({
      ...styles,
      border: isRed ? "1px solid red" : "",
    }),
    option: (styles, { isFocused, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "grey" : null,
        color: isFocused ? "white" : isDisabled ? "grey" : "black",
        minHeight: '40px',
      }
    },
  }

  _.extend(defaultStyles, styles)

  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      onFocus={onFocus}
      onMenuOpen={onMenuOpen}
      menuIsOpen={menuIsOpen}
      onClick={onClick}
      onBlur={onBlur}
      value={value}
      label={label}
      key={subKey}
      isOptionDisabled={isOptionDisabled}
      styles={defaultStyles}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      isDisabled={isDisabled}
      className={`${isMulti ? "basic-multi-select" : ""}`}
      isMulti={isMulti ? "isMulti" : ""}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "grey",
          primary: "grey",
          primary50: "grey",
        },
      })}
    />
  )
}

export default Selector
