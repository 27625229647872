import { useTranslation } from "react-i18next"
import Selector from "../../../../helpers/Selector"
import { SeriesContext } from "../../../../context/SeriesContext"
import { useContext } from "react"
import i18n from "../../../../i18n"
import { SelectionContext } from "../../../../context/SelectionContext"


const GroupSelector = () => {

    const { t } = useTranslation()

    const {

        groups,
        loadingGroups,

    } = useContext(SeriesContext)

    const {
        setGroup
    } = useContext(SelectionContext)


    return (
        <div className="columns is-centered">
            <div className="column is-half">
                <div className="content has-text-centered">
                    <h3>{t("series.group")}</h3>
                </div>
                <Selector
                    options={groups?.map(
                        group => (
                            {
                                value: group.id,
                                label: i18n.language === "en" ?
                                    group.nameEn :
                                    group.nameRu,
                                ...group
                            }
                        )
                    )}
                    placeholder={t('series.selectDataType')}
                    isLoading={loadingGroups}
                    onChange={target => {
                        setGroup(target.value)
                    }}

                />
            </div>
        </div>
    )

}

export default GroupSelector