import { useContext } from "react"
import Selector from "../../../../../helpers/Selector"
import { dateColumnsOptions, outputFormatOptions, showLastOptions } from "../../../../../utils"
import i18n from "../../../../../i18n"
import { useTranslation } from "react-i18next"
import { SelectionContext } from "../../../../../context/SelectionContext"
import { SeriesContext } from "../../../../../context/SeriesContext"
import dayjs from "dayjs"


import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker"

const DateSection = () => {

    const { t } = useTranslation()


    const {


        frequencies,
        loadingFrequencies,

    } = useContext(SeriesContext)



    const {

        loadingFieldsValues,

        lastUpdateDate,
        setLastUpdateDate,


        frequencyCount,

        setSelectedFrequencies,

        outputFormat,
        setOutputFormat,

        startDate,
        setStartDate,

        endDate,
        setEndDate,

        includePreliminary,
        setIncludePreliminary,

        isDownloadEmpty,
        setIsDownloadEmpty,

        selectDateColumnRed,
        setSelectDateColumnRed,

        setSelectedDateColumns


    } = useContext(SelectionContext)


    return (
        <div className="section">


            <div className="content has-text-centered">
                <h3>{t("series.outputSettings")}</h3>
            </div>
            <div className="columns">

                <div className="column has-text-centered">


                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label">{t("series.outputFormat")}</label>
                        </div>
                    </div>
                    <Selector

                        options={outputFormatOptions?.[i18n?.language]}
                        onChange={target => setOutputFormat(target)}
                        value={outputFormat}

                    />

                </div>

                <div className="column has-text-centered">

                    <div className="columns is-centered mb-0">
                        <div className="column is-narrow">
                            <label className="label">{t("series.frequencies")}</label>
                        </div>
                        <div className="column mb-0 is-narrow">
                            <i className="fa fa-question fa-lg" title={t("series.frequenciesInfo")}></i>
                        </div>
                    </div>
                    <Selector

                        options={Object.keys(frequencyCount)?.sort(
                            (a, b) => frequencies[a] - frequencies[b]
                        )?.map(
                            item => ({
                                label: item,
                                value: item
                            })
                        )}


                        isMulti={true}
                        onChange={targets => {
                            setSelectedFrequencies(targets)
                        }}
                        isLoading={loadingFieldsValues || loadingFrequencies}

                    />
                </div>
                <div className="column has-text-centered">

                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label">{t("series.showLast")}</label>

                        </div>
                    </div>
                    <Selector
                        options={showLastOptions?.[i18n?.language]}
                        placeholder={t("series.showLast")}
                        defaultValue={showLastOptions?.[i18n?.language]?.[4]}
                        onChange={target => {
                            switch (target.value) {
                                case "A":
                                    setStartDate(null)
                                    setEndDate(new Date())
                                    break
                                case "5y":
                                    setStartDate(new Date(dayjs().subtract(5, "y")))
                                    setEndDate(new Date())
                                    break
                                case "10y":
                                    setStartDate(new Date(dayjs().subtract(10, "y")))
                                    setEndDate(new Date())
                                    break
                                default:
                                    setStartDate(
                                        new Date(dayjs().subtract(1, target.value))
                                    )
                                    setEndDate(new Date())
                            }
                        }}
                    />
                </div>
                <div className="column">

                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label has-text-centered">{t("series.includePreliminary")}</label>
                        </div>
                    </div>
                    <button
                        className={`button is-fullwidth ${includePreliminary ? "is-success" : ""}`}
                        onClick={() => setIncludePreliminary(!includePreliminary)}
                    >
                        {includePreliminary ? t("series.yes") : t("series.no")}
                    </button>
                </div>
            </div>

            <div className="columns is-centered">

                <div className="column has-text-centered">

                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label">{t("series.startDate")}</label>
                        </div>
                    </div>
                    <DatePicker
                        className="input is-fullwidth"
                        selected={startDate}
                        dateFormat="dd.MM.yyyy"
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        isClearable={true}
                        showYearDropdown
                    />
                </div>


                <div className="column has-text-centered">

                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label">{t("series.endDate")}</label>

                        </div>
                    </div>
                    <DatePicker
                        className="input"
                        selected={endDate}
                        // placeholderText={t("series.endDate")}
                        dateFormat="dd.MM.yyyy"
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        isClearable={true}
                        showYearDropdown
                    />

                </div>

                <div className="column has-text-centered">
                    <div className="columns mb-0">
                        <div className="column mb-0">
                            <label className="label">{t("series.updateDate")} <i className="fa fa-question fa-lg" title={t("series.lastUpdateInfo")} /></label>

                        </div>

                    </div>
                    <DatePicker
                        className="input is-fullwidth mt-0"
                        selected={lastUpdateDate}
                        dateFormat="dd.MM.yyyy"
                        onChange={date => setLastUpdateDate(date)}
                        selectsEnd
                        startDate={endDate}
                        endDate={endDate}
                        maxDate={endDate}
                        isClearable={true}
                        showYearDropdown
                    />


                </div>
                <div className="column">

                    <div className="columns mb-0">
                        <div className="column">
                            <label className="label has-text-centered">{t("series.isDownloadEmpty")}</label>
                        </div>
                    </div>
                    <button
                        className={`button is-fullwidth ${isDownloadEmpty ? "is-success" : ""}`}
                        onClick={() => setIsDownloadEmpty(!isDownloadEmpty)}
                    >
                        {isDownloadEmpty ? t("series.yes") : t("series.no")}
                    </button>
                </div>

            </div>


            <div className="columns is-centered">
                <div className="column is-half has-text-centered">
                    <label className="label">{t("series.dateColumns")}</label>
                    <Selector

                        options={dateColumnsOptions?.[i18n.language]}
                        isMulti={true}
                        defaultValue={dateColumnsOptions?.[i18n.language]?.slice(0, 4)}
                        isRed={selectDateColumnRed}
                        onFocus={() => setSelectDateColumnRed(false)}
                        onChange={targets => {
                            setSelectedDateColumns(targets)
                        }}

                    />

                </div>
            </div>

        </div>

    )
}

export default DateSection