export const textRu =
    <div className="content">

        <h3 className="has-text-centered">Добро пожаловать на сайт базы данных Chronos!</h3>
        <p>Основная цель проекта Chronos &mdash; агрегировать доступные внутренние и внешние рыночные и коммерческие данные на организованной цифровой платформе для сотрудников Компании.</p>
        <p>Ниже описаны основные разделы пользовательского интерфейса Chronos.</p>
        <h3 className="has-text-centered">Тип данных</h3>
        <p>База содержит следующие наборы данных:</p>
        <ul>
            <li>Ссылки на исторические рыночные цены следующих агентств:
                <ul>
                    <li>CRU</li>
                    <li>Fertecon</li>
                    <li>Argus</li>
                </ul>
            </li>
            <li>Региональные индикативные цены (российские ИРЦ)</li>
            <li>Индикативные цены CFR</li>
            <li>Курсы валют</li>
        </ul>
        <h3 className="has-text-centered">Выбор данных</h3>
        <ul>
            <li>Для каждого типа данных пользователь может фильтровать поиск по набору атрибутов. Например, для цен пользователь может выбрать источник данных, тип продукта и географическое расположение рынка, а также при необходимости выполнить дополнительную фильтрацию.</li>
            <li>Пользователь может оставить фильтр пустым, тогда данные не будут фильтроваться по этому атрибуту и будут загружены полностью.</li>
        </ul>
        <h3 className="has-text-centered">Порядок в выдаче данных</h3>
        <ul>
            <li>Пользователь может выбрать порядок/структуру атрибутов для вывода. Для этого используйте интерфейс перетаскивания после выбора соответствующих фильтров.</li>
            <li>Пользователь может исключить некоторые поля из заголовка таблицы (в формате xlsx, см. ниже), нажав кнопку Mute рядом с соответствующими полями.</li>
            <li>Пользователь может объединить одинаковые значения в заголовке выгружаемой таблицы, нажав кнопку Merge рядом с соответствующими полями.</li>
        </ul>
        <h3 className="has-text-centered">Настройки выдачи</h3>
        <ul>
            <li>Доступны два формата выдачи данных &mdash; стандартный вид в xlsx и плоский вид в csv.</li>
            <li>Стандартный формат загрузки в xlsx содержит два листа для каждой временной частоты данных: первый лист с названием Data W (для недельных данных) содержит значения рядов, второй лист с названием Description W содержит описания рядов.</li>
            <li>Пользователь может выбрать частоту данных, например <strong>D</strong> для дневных данных,<strong>W</strong> для недельных данных, начинающихся по понедельникам. Для недельных данных, начинающихся в другие дни, используются <strong>W_THU</strong><strong>, W_WED</strong> и т.д.; <strong>W2, М, Q, Y</strong> используются для двухнедельных, месячных, квартальных и годовых данных соответственно.</li>
            <li>Пользователь может выбрать период, за который необходимо загрузить данные, используя фильтры <strong>Начало</strong> и <strong>Конец</strong>. Кроме того, фильтр <strong>Дата последнего обновления</strong> дает возможность исключать из выдачи неактуальные ряды.</li>
            <li>Пользователь может включить в загрузку предварительные данные. Например, годовые данные, агрегированные за еще не закончившийся год, будут выделены серым цветом.</li>
            <li>Пользователь может выбрать до четырех форматов дат в выгрузке, cм. информацию ниже о форматах дат.</li>
        </ul>
        <p><strong><em>Форматы дат в Chronos</em></strong></p>
        <ul>
            <li>В Chronos для каждого значения статистического ряда может быть определено до четырех дат: дата начала соответствующего периода, дата окончания периода, дата публикации данных и короткая запись даты.</li>
            <li>Краткая запись 2023W01 используется для недельных данных, начинающихся в понедельник. Первая неделя года &mdash; это та, на которую выпадает первый понедельник года.</li>
            <li>Краткая запись 2023M01 используется для месячных данных, начиная с первого дня данного месяца.</li>
            <li>Краткие записи 2023Q1, 2023 используются для квартальных и годовых периодов, начинающихся с первого дня соответствующего квартала или года.</li>
            <li>В остальных случаях краткая запись даты совпадает с датой начала соответствующего периода.</li>
        </ul>
    </div>
