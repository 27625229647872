import { useContext, useEffect, useState } from "react";
import { SeriesContext } from "../../../../context/SeriesContext";
import Selector from "../../../../helpers/Selector";
import LoadingSpinner from "../../../../helpers/LoadingSpinner";
import axios from "axios";
import { PYTHON_API_URL, isEmpty } from "../../../../utils";
import i18n from "../../../../i18n";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DraggableRow from "../../../../helpers/DraggableRow";
import { handleError } from "../../../../errors";

const GroupsUpdateForm = () => {

    const {
        setNeedRefetchFields,

        groups,
        loadingGroups,
        setNeedRefetchGroups,


    } = useContext(SeriesContext);

    const { t } = useTranslation()



    const [nameEn, setNameEn] = useState("")
    const [nameRu, setNameRu] = useState("")
    const [numberFormat, setNumberFormat] = useState("")

    const [isUpdate, setIsUpdate] = useState(false)

    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const [redNameEn, setRedNameEn] = useState(false)
    const [redNameRu, setRedNameRu] = useState(false)


    const [showReorder, setShowReorder] = useState(false)

    const [updateId, setUpdateId] = useState(null)
    const [selectorValue, setSelectorValue] = useState(null)

    const [isLoadingUpdateFieldsOrder, setIsLoadingUpdateFieldsOrder] = useState(false)


    const [updatedFields, setUpdatedFields] = useState([])

    const [loadingUpdatedFields, setLoadingUpdatedFields] = useState(false)

    const getFieldsByGroupId = async (id) => {

        setLoadingUpdatedFields(true)
        try {

            const resp = await axios.get(`${PYTHON_API_URL}/restricted/fields/list?id=${id}`)
            const { fields } = resp.data

            setUpdatedFields(fields)

        } catch (error) {
            handleError(error)
        }
        setLoadingUpdatedFields(false)
    }

    useEffect(() => {
        if (selectorValue !== null) {
            getFieldsByGroupId(selectorValue.value)
        }
        //eslint-disable-next-line
    }, [selectorValue])

    const [dragId, setDragId] = useState();

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        const dragField = updatedFields.find((field) => field.id === dragId);
        const dropField = updatedFields.find((field) => field.id === ev.currentTarget.id);

        const dragBoxOrder = dragField.order;
        const dropBoxOrder = dropField.order;

        const update = dragBoxOrder >= dropBoxOrder ? +1 : -1

        const newFieldsState = updatedFields.map((box) => {
            if (box.id === dragId) {
                box.order = dropBoxOrder;
            } if (box.id === ev.currentTarget.id) {
                box.order = box.order + update
            } else if ((box.order < Math.max(dragBoxOrder, dropBoxOrder)) && (box.order > Math.min(dragBoxOrder, dropBoxOrder))) {
                box.order = box.order + update
            }
            return box;
        });


        setUpdatedFields(newFieldsState);
    };



    const clearForm = () => {
        setNameEn("")
        setNameRu("")
        setNumberFormat("")

        setIsLoadingCreate(false)
        setIsLoadingUpdate(false)
        setIsLoadingDelete(false)

        setUpdatedFields([])

        setSelectorValue(null)
    }

    const createGroup = async e => {
        e.preventDefault()

        if (isEmpty(nameEn)) {
            setRedNameEn(true)
            return toast.error(t('groups.emptyNameEn'))
        }

        if (isEmpty(nameRu)) {
            setRedNameRu(true)
            return toast.error(t('groups.emptyNameRu'))
        }


        setIsLoadingCreate(true)
        const data = {
            nameEn,
            nameRu,
            numberFormat
        }


        try {
            await axios.post(`${PYTHON_API_URL}/restricted/groups`, data)



            setNeedRefetchGroups(true)
            toast.success(t('groups.created'))
        } catch (error) {
            handleError(error)
        }

        clearForm()

    }

    const updateGroup = async () => {

        setIsLoadingUpdate(true)
        const data = {
            id: updateId,
            nameEn,
            nameRu,
            numberFormat
        }

        try {
            await axios.patch(`${PYTHON_API_URL}/restricted/groups?id=${updateId}`, data)

            setNeedRefetchGroups(true)
            toast.success(t('groups.updated'))
        } catch (error) {

            handleError(error)
        }

        setIsLoadingUpdate(false)
        clearForm()


    }

    const updateFieldsOrder = async () => {

        setIsLoadingUpdateFieldsOrder(true)
        const data = {
            "fields": updatedFields
        }

        try {
            await axios.patch(`${PYTHON_API_URL}/restricted/fields/update_fields_order`, data)


            setNeedRefetchFields(true)
            toast.success(t('groups.updated'))
        } catch (error) {
            handleError(error)
        }

        setIsLoadingUpdateFieldsOrder(false)


    }

    const deleteGroup = async () => {

        setIsLoadingDelete(true)

        try {
            await axios.delete(`${PYTHON_API_URL}/restricted/groups?id=${updateId}`)

            setNeedRefetchGroups(true)
            toast.success(t('groups.deleted'))
        } catch (error) {
            handleError(error)
        }

        setIsLoadingDelete(false)
        clearForm()

    }


    const GroupsSelector = () =>
    (
        <Selector
            options={groups?.map(
                group => (
                    {
                        value: group.id,
                        label: i18n.language === "en" ?
                            group.nameEn :
                            group.nameRu,
                        ...group
                    }
                )
            )}
            isLoading={loadingGroups}
            value={selectorValue}
            onChange={target => {
                setSelectorValue(target)
                setNameEn(target.nameEn)
                setNameRu(target.nameRu)
                setNumberFormat(target.numberFormat)
                setUpdateId(target.id)
            }}
        />
    )

    const ModeSwitcher = () =>
    (
        <div className="buttons has-addons">
            <div
                className={`button ${isUpdate ? "" : "is-success"}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('groups.modeCreate')}
            </div>
            <div
                className={`button ${isUpdate ? "is-success" : ""}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('groups.modeUpdate')}
            </div>


        </div>
    )

    const FieldsUpdate = () => <>

        <div className="columns">
            <div className="column">

                <div className="field">
                    <div className="control">
                        <input
                            className={`input ${redNameEn ? "is-danger" : ""}`}
                            value={nameEn}
                            placeholder={t('groups.nameEn')}
                            onChange={e => setNameEn(e.target.value)}

                        />
                    </div>
                </div>


            </div>

            <div className="column">
                <div className="field">
                    <div className="control">
                        <input
                            className={`input ${redNameRu ? "is-danger" : ""}`}
                            value={nameRu}
                            placeholder={t('groups.nameRu')}
                            onChange={e => setNameRu(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="column">

                <div className="field">
                    <div className="control">
                        <input
                            className={`input`}
                            value={numberFormat}
                            placeholder={t('groups.numberFormat')}
                            onChange={e => setNumberFormat(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>

    const ReorderList = () => (
        <div className="columns is-centered">
            <div className="column is-half">
                {updatedFields
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((field) => {

                        return <DraggableRow
                            key={field.order}
                            order={field.order}
                            id={field.id}

                            title={i18n.language === "en" ? field.nameEn : field.nameRu}
                            handleDrag={handleDrag}
                            handleDrop={handleDrop}
                        />
                    }
                    )}

            </div>
        </div>
    )

    const ReorderForm = () => (

        <>
            <div className="columns is-centered">

                <div className="column has-text-centered">
                    <div className="field">
                        <div className="control">

                            <div
                                className="button is-centered"
                                onClick={() =>
                                    setShowReorder(!showReorder)
                                }
                            >
                                <span className="icon is-small">
                                    <i className={`fa fa-angle-${showReorder ? "up" : "down"}`}></i>

                                </span>

                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {t('groups.showReorder')}


                            </div>
                        </div>
                    </div>


                    {
                        showReorder ?
                            <>
                                {loadingUpdatedFields ? <LoadingSpinner /> : <ReorderList />}
                            </>
                            : null
                    }
                </div>

            </div>




        </>
    )



    const CreateModeButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingCreate ? "is-loading" : ""} ${isEmpty(nameEn) || isEmpty(nameRu) ? "is-static" : ""}`}
                    onClick={e => createGroup(e)}
                >
                    {t('groups.create')}
                </div>
            </div>
        </div>
    )

    const UpdateModeButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingUpdate ? "is-loading" : ""} ${selectorValue === null ? "is-static" : ""}`}
                    onClick={updateGroup}
                >
                    {t('groups.update')}
                </div>
            </div>
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingUpdateFieldsOrder ? "is-loading" : ""} ${selectorValue === null ? "is-static" : ""}`}
                    onClick={updateFieldsOrder}
                >
                    {t('groups.updateFieldsOrder')}
                </div>
            </div>
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-danger ${isLoadingDelete ? "is-loading" : ""} ${selectorValue === null ? "is-static" : ""}`}
                    onClick={deleteGroup}
                >
                    {t('groups.delete')}
                </div>
            </div>
        </div>
    )




    return <>

        <div className="box">

            <div className="label has-text-centered">
                {t("groups.title")}
            </div>

            <div className="columns">
                <div className="column">
                    {
                        isUpdate ?
                            <GroupsSelector />
                            :
                            null
                    }
                </div>
                <div className="column is-narrow">
                    <ModeSwitcher />
                </div>
            </div>


            {FieldsUpdate()}


            {
                isUpdate ?
                    <>
                        <ReorderForm />
                        <UpdateModeButtons />
                    </>
                    : <CreateModeButtons />
            }




        </div>
    </>
}

export default GroupsUpdateForm