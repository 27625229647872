export const textEn =
    <div className="content">
        <h3 className="has-text-centered">Welcome to the Chronos database website!</h3>
        <p>The main purpose of the Chronos project is to aggregate available internal and external market and commercial data on an organized digital platform for Company employees.</p>
        <p>The text below describes the main sections of the Chronos user interface.</p>
        <h3 className="has-text-centered">Type of data</h3>
        <p>The database contains the following datasets:</p>
        <ul>
            <li>Historical Market prices references of the following agencies:</li>
            <ul>
                <li>CRU</li>
                <li>Fertecon</li>
                <li>Argus</li>
            </ul>
            <li>Regional indicative prices (Russian RPI)</li>
            <li>CFR Price indicatives</li>
            <li>Exchange rates</li>
        </ul>
        <h3 className="has-text-centered">Data selection</h3>
        <ul>
            <li>For each data type, user can filter the search to specific request by a set of attributes. For example, for prices user can select the data source, the type of product he is looking for, the geographic location of the market and filter further if necessary.</li>
            <li>The user can leave the filter empty, then the data will not be filtered by this attribute and will be fully downloaded.</li>
        </ul>
        <h3 className="has-text-centered">Order of data output</h3>
        <ul>
            <li>User can choose the order/structure of attributes for the output. To do this, use drag-and-drop interface after selecting the appropriate filters.</li>
            <li>User can exclude some fields from the table header (in xlsx format, see below) by clicking the Mute button next to the corresponding fields.</li>
            <li>User can merge equal values in the header of the output table by clicking the Merge button next to the corresponding fields.</li>
        </ul>
        <h3 className="has-text-centered">Output settings</h3>
        <ul>
            <li>Two formats for downloading data are available - standard view in xlsx and flat view in csv.</li>
            <li>Standard download format in xlsx contains two sheets for every frequency. First sheet includes series values named as Data W (for weekly data), and sheet with series properties named as Description W.</li>
            <li>User can select data frequency such as <strong>D</strong> for daily data, <strong>W</strong> for weekly data starting on Monday. For weekly series starting on other days <strong>W_TUE, W_WED</strong> etc. are used, and <strong>W2, M, Q, Y</strong> are for bi-weekly, monthly, quarterly, and annual data respectively.</li>
            <li>User can select the period for which data needs to be downloaded using <strong>From</strong> and <strong>To</strong> filters. Additionally, <strong>Last update date</strong> filter allows to exclude series which are not up to date from the output.</li>
            <li>User can include preliminary data in the output. For example, annual data aggregated for a year that has not yet ended, this will be highlighted in gray.</li>
            <li>User can select up to four date formats in the output. See the information on date formats below.</li>
        </ul>
        <p><strong><em>Date formats in Chronos</em></strong></p>
        <ul>
            <li>In Chronos, for each value of a statistical series there are up to four types of date definitions: the start date of the corresponding period, the end date of the period, the date of publication of the data, and a short record of the date.</li>
            <li>2023W01 is the short record for weekly data starting on Monday. The first week of the year is the one when the first Monday of the year falls.</li>
            <li>2023M01 is the short record for monthly data starting on the first day of a given month.</li>
            <li>2023Q1, 2023 are the short records used for quarterly and annual periods beginning on the first day of the corresponding quarter or year.</li>
            <li>In other cases, the short date entry coincides with the start date of the corresponding period.</li>
        </ul>

    </div >