import { Switch } from "react-router-dom"
import NavBar from "../helpers/NavBar"
import NavbarSections from "./NavbarSections"

import { Redirect, Route, useRouteMatch } from "react-router"

import RestrictedRoutes from "./RestrictedRoutes"
// import FilesView from "./views/files/FilesView"
// import FilesContextProvider from "../context/FilesContext"
import SeriesView from "./views/series/SeriesView"
// import MainView from "./views/main/MainView"
import SeriesContextProvider from "../context/SeriesContext"
import MainView from "./views/main/MainView"
import NewsView from "./views/news/NewsView"
import NewsContextProvider from "../context/NewsContext"
import ReportsView from "./views/reports/ReportsView"

const CommonWorkspace = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <NavBar>
        <NavbarSections />
      </NavBar>

      <div className="section has-background-light">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <Switch>

                <Route exact path={path}>
                  <Redirect to={path + "/main"} />
                </Route>

                <Route path={path + "/main"}>
                  <div className="columns is-centered">
                    <div className="column is-8">
                      <MainView />
                    </div>
                  </div>
                </Route>

                <Route path={path + "/news"}>
                  <NewsContextProvider >
                    <NewsView />
                  </NewsContextProvider>
                </Route>

                {/* <Route path={path + "/files"}>
                  <CategoryContextProvider>
                    <FilesContextProvider>
                      <div className="columns is-centered">
                        <div className="column is-fullwidth">
                          <FilesView />
                        </div>
                      </div>
                    </FilesContextProvider>
                  </CategoryContextProvider>
                </Route> */}

                <Route path={path + "/series"}>
                  <div className="columns is-centered">
                    <div className="column is-fullwidth">
                      <SeriesContextProvider>

                        <SeriesView />
                      </SeriesContextProvider>
                    </div>
                  </div>
                </Route>

                <Route path={path + "/reports"}>
                  <ReportsView />
                </Route>

                <RestrictedRoutes />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommonWorkspace
