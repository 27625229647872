import { createContext, useState, useEffect } from "react"
import jwt from "jsonwebtoken"
import axios from "axios"
import { PYTHON_API_URL } from "../utils"

const userFromToken = token => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token
  if (token) {

    const { userID, email, userType } = jwt.decode(token)
    return { userID, email, userType }
  } else {
    return {}
  }
}

const tokenFromStorage = localStorage.getItem("token") || ""
const userFromStorage = userFromToken(tokenFromStorage)

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(tokenFromStorage)
  const [user, setUser] = useState(userFromStorage)

  const validateToken = async () => {

    try {

      await axios.get(`${PYTHON_API_URL}/restricted/session/validate`)

      setUser(userFromToken(token))

    } catch (error) {
      await logout()
    }

  }

  useEffect(() => {

    if (token) validateToken()
    //eslint-disable-next-line
  }, [token])

  const login = newToken => {
    localStorage.setItem("token", newToken)
    setUser(userFromToken(newToken))
    setToken(newToken)
  }

  const logout = async () => {
    localStorage.removeItem("token")
    setUser({})
    setToken("")
    await axios.delete(`${PYTHON_API_URL}/restricted/session`)

  }

  const value = {
    token,
    user,
    login,
    logout,
    isSigned: token.length,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
