import InviteView from "./views/invites/InviteView"
import { UserContext } from "../context/UserContext"
import UserView from "./views/users/UserView"
import { Route, useRouteMatch } from "react-router"
import { useContext } from "react"
import InvitesContextProvider from "../context/InvitesContext"

const RestrictedRoutes = () => {
  const {
    user: { userType },
    isSigned,
  } = useContext(UserContext)

  const { path } = useRouteMatch()
  var views = <></>
  switch (userType) {
    case "admin":
      views = (
        <>
          <Route path={path + "/invites"}>
            <InvitesContextProvider>
              <InviteView />
            </InvitesContextProvider>
          </Route>
          <Route path={path + "/users"}>
            <UserView />
          </Route>

        </>
      )
      break
    default:
      views = <></>
  }
  return isSigned ? views : <></>
}

export default RestrictedRoutes
