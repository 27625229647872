// Text field with buttons to save update message
//

import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { PYTHON_API_URL, isEmptyEditor, messageTypeOptions } from "../../../utils"
import { handleError } from "../../../errors"
import axios from "axios"

import { convertToRaw, EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import draftToHtml from 'draftjs-to-html';
import Selector from "../../../helpers/Selector"
import { NewsContext } from "../../../context/NewsContext"

const defaultContentState = EditorState.createWithContent(
    ContentState.createFromBlockArray(
        convertFromHTML('')
    )
)

const NewsCreate = () => {

    const { t, i18n } = useTranslation()

    const { setNeedRefetchNews } = useContext(NewsContext);


    const [textRu, setTextRu] = useState(defaultContentState)
    const [textEn, setTextEn] = useState(defaultContentState)

    const [redTextRu, setRedTextRu] = useState(false)
    const [redTextEn, setRedTextEn] = useState(false)


    const [messageType, setMessageType] = useState(messageTypeOptions?.[i18n?.language]?.[0])


    const [submittingCreate, setSubmittingCreate] = useState(false)

    const [sure, setSure] = useState(false)


    const createMessage = async (e) => {
        e.preventDefault()

        if (isEmptyEditor(textRu)) {
            setRedTextRu(true)
            return toast.error(t("news.ruError"))
        }

        if (isEmptyEditor(textEn)) {
            setRedTextEn(true)
            return toast.error(t("news.enError"))
        }

        setSubmittingCreate(true)

        try {

            const data = {
                locales: {
                    ru: draftToHtml(convertToRaw(textRu.getCurrentContent())),
                    en: draftToHtml(convertToRaw(textEn.getCurrentContent())),
                }
            }

            await axios.post(
                `${PYTHON_API_URL}/restricted/news?messageType=${messageType.value}`,
                data
            )





            setTextRu(defaultContentState)
            setTextEn(defaultContentState)
            setSubmittingCreate(false)
            setSure(false)
            setNeedRefetchNews(true)
            toast.success(t("news.success"))
        } catch (error) {

            setSubmittingCreate(false)
            setSure(false)
            return handleError(error)
        }

    }


    return (

        <div className="box">

            {/* <ReCaptcha /> */}

            <div className="columns is-vcentered">
                <div className="column"></div>
                <div className="column is-three-quarters">

                    <div className="label has-text-centered">{t("news.ruEditor")}</div>

                    <div className="field">
                        <div className="control">
                            <Editor
                                editorState={textRu}
                                editorStyle={{
                                    border: `1px solid ${redTextRu ? "red" : "grey"}`
                                    ,
                                    height: '200px'
                                }}
                                onFocus={() => {
                                    setRedTextRu(false)
                                }}
                                onEditorStateChange={e => {
                                    setTextRu(e)
                                }}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="label has-text-centered">{t("news.enEditor")}</div>

                    <div className="field">
                        <div className="control">
                            <Editor
                                editorState={textEn}
                                editorStyle={{
                                    border: `1px solid ${redTextEn ? "red" : "grey"}`
                                    ,
                                    height: '200px'
                                }}
                                onFocus={() => {
                                    setRedTextEn(false)
                                }}
                                onEditorStateChange={e => {
                                    setTextEn(e)
                                }}
                            />
                        </div>
                    </div>


                    <Selector
                        options={messageTypeOptions?.[i18n.language]}
                        value={messageType}
                        onChange={target => {
                            setMessageType(target)
                        }}
                    />

                    <div className="section">


                        {sure ? (

                            <>
                                <div className="has-text-centered">{t("news.sureSend.title")}</div>
                                <div className="columns is-centered">
                                    <div className="column is-one-quarter">
                                        <button
                                            className={`button is-small is-fullwidth is-link ${submittingCreate ? "is-loading" : ""
                                                }`}
                                            onClick={e => createMessage(e, true)}
                                            disabled={submittingCreate}
                                        >
                                            {t("news.sureSend.yes")}
                                        </button>
                                    </div>
                                    <div className="column is-one-quarter">
                                        <button
                                            className={`button is-small is-fullwidth is-danger`}
                                            onClick={() => setSure(false)}
                                            disabled={submittingCreate}
                                        >
                                            {t("news.sureSend.no")}
                                        </button>
                                    </div>
                                </div>
                            </>


                        ) : (
                            <div className="columns is-centered ">
                                <div className="column is-half">
                                    <button
                                        className={`button is-small is-fullwidth is-half is-link ${submittingCreate ? "is-loading" : ""
                                            }`}
                                        onClick={() => setSure(true)}
                                        disabled={submittingCreate}
                                    >
                                        {t("news.create")}
                                    </button>
                                </div>

                            </div>
                        )}

                    </div>
                </div >
                <div className="column">

                </div>
            </div>
        </div>


    )
}

export default NewsCreate