import { useRouteMatch, Switch, Route, Redirect, Link } from "react-router-dom"

import LoginForm from "./LoginForm"
import ApplyReset from "./ApplyReset"
import LangAuthSelector from "./LangAuthSelector"

import SignUp from "./SignUp"
import { useTranslation } from "react-i18next"

const Login = ({ userType }) => {
  const { path, url } = useRouteMatch()
  const { t } = useTranslation()

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to={url + "/signIn"}></Redirect>
        </Route>
        <Route exact path={path + "/signIn"}>
          <LoginForm userType={userType} />

          <hr />
          <div className="columns is-centered">
            <div className="column">
              <Link className="has-text-grey" to={url + "/applyReset"}>
                {t("auth.forgotPassword")}
              </Link>
              &nbsp;|&nbsp; <LangAuthSelector />
            </div>
          </div>
        </Route>

        <Route path={path + "/signUp"}>
          <SignUp userType={userType} />
          <hr />
          <div className="columns is-centered">
            <div className="column">
              <LangAuthSelector />
            </div>
          </div>
        </Route>

        <Route path={path + "/applyReset"}>
          <ApplyReset userType={userType} />
        </Route>
      </Switch>
    </>
  )
}

export default Login
