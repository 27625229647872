import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"
import i18n from "../i18n"

const useGroups = () => {
    const [groups, setGroups] = useState([])

    const [loading, setLoading] = useState(false)
    const [needRefetch, setNeedRefetch] = useState(false)

    const fetchGroups = async () => {
        try {
            const resp = await axios.get(`${PYTHON_API_URL}/restricted/groups/list`)

            const { groups: groupsList } = resp.data

            setGroups(
                groupsList?.map(
                    group => (
                        {
                            value: group.id,
                            label: i18n.language === "en" ?
                                group.nameEn :
                                group.nameRu,
                            ...group
                        }
                    )
                ))
            setLoading(false)
            setNeedRefetch(false)
        } catch (error) {
            setLoading(false)
            return handleError(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchGroups()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (needRefetch) {
            setLoading(true)
            fetchGroups()
        }
        // eslint-disable-next-line
    }, [needRefetch])

    return { loading, groups, setNeedRefetch }
}

export default useGroups
