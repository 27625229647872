import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <div className="columns is-centered">
        <div className="column is-centered is-narrow">
          <div className="content has-text-left">
            <p>
              {t("footer.productManager")}
              <a href="mailto:chronos@statspace.io">Maria.Golovanova@eurochemgroup.ae</a>
            </p>
            <p>
              {t("footer.dataAnalyst")}
              <a href="mailto:chronos@statspace.io">Alla.Sergacheva@eurochem.ru</a>
            </p>
            <p>
              {t("footer.techSupport")}
              <a href="mailto:chronos@statspace.io">Matvey.Zekhov@eurochem.ru</a>
            </p>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
