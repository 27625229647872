import { useContext, useState } from "react";
import DraggableRow from "../../../../../helpers/DraggableRow"
import { SelectionContext } from "../../../../../context/SelectionContext";
import { useTranslation } from "react-i18next";


const OrderSection = () => {

    const [dragId, setDragId] = useState(null);

    const { t } = useTranslation()



    const {
        fields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

    } = useContext(SelectionContext)

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
    };

    const handleDrop = (ev) => {
        const dragField = selectedFieldsOrder.find((field) => field.id === dragId);
        const dropField = selectedFieldsOrder.find((field) => field.id === ev.currentTarget.id);

        const dragBoxOrder = dragField.order;
        const dropBoxOrder = dropField.order;

        const update = dragBoxOrder >= dropBoxOrder ? +1 : -1

        const newFieldsState = selectedFieldsOrder.map((box) => {
            if (box.id === dragId) {
                box.order = dropBoxOrder;
            } if (box.id === ev.currentTarget.id) {
                box.order = box.order + update
            } else if ((box.order < Math.max(dragBoxOrder, dropBoxOrder)) && (box.order > Math.min(dragBoxOrder, dropBoxOrder))) {
                box.order = box.order + update
            }
            return box;
        });

        setSelectedFieldsOrder(newFieldsState);
    };

    const handleBlock = (id) => {
        setSelectedFieldsOrder(
            selectedFieldsOrder.map(
                item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isBlocked: !item.isBlocked,
                            isUnmerged: true
                        }
                    }
                    return item;
                }
            )
        );
    };


    const handleUnmerge = (id) => {
        setSelectedFieldsOrder(
            selectedFieldsOrder.map(
                item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isUnmerged: !item.isUnmerged,
                        }
                    }
                    return item;
                }
            )
        );
    };


    return (

        <div className="section">

            <div className="columns is-centered">
                <div className="column is-10">

                    <div className="content has-text-centered">
                        <h3> {t("searchFields.order")}</h3>
                    </div>

                    {selectedFieldsOrder
                        .sort((a, b) => a.order - b.order)
                        .map((field) => {


                            const curSelectedField = fields?.find(item => item?.id === field?.id)

                            return <div className="columns is-left" key={field?.id}>
                                <div className="column is-8">
                                    <DraggableRow
                                        key={field.order}
                                        id={field.id}

                                        onDelete
                                        title={field.title}
                                        handleDrag={handleDrag}
                                        handleDrop={handleDrop}
                                        isBlocked={false}
                                    />

                                </div>


                                <div className="column is-narrow">
                                    <button
                                        className={`button ${field.isBlocked ? "is-success" : ""}`}
                                        disabled={curSelectedField?.isAlwaysSelected}
                                        onClick={() => {
                                            handleBlock(field.id)
                                        }}
                                    >
                                        Mute
                                    </button>
                                </div>
                                <div className="column is-narrow">
                                    <button
                                        className={`button ${!field.isUnmerged ? "is-success" : ""}`}
                                        disabled={field.isBlocked}
                                        onClick={() => {
                                            handleUnmerge(field.id)
                                        }}
                                    >
                                        Merge
                                    </button>
                                </div>
                            </div>
                        }
                        )}

                </div>
            </div>
        </div>

    )
}

export default OrderSection