import { useContext, useState } from "react";
import { SeriesContext } from "../../../../context/SeriesContext";
import Selector from "../../../../helpers/Selector";
import axios from "axios";
import { PYTHON_API_URL, converters, dataTypes, isEmpty } from "../../../../utils";
import i18n from "../../../../i18n";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { handleError } from "../../../../errors";

const ModesUpdateForm = () => {

    const {

        modes,
        loadingModes,
        setNeedRefetchModes,

        groups,
        loadingGroups

    } = useContext(SeriesContext);

    const { t } = useTranslation()



    const [nameEn, setNameEn] = useState("")
    const [nameRu, setNameRu] = useState("")
    const [type, setType] = useState(null)
    const [converter, setConverter] = useState(null)

    const [isUpdate, setIsUpdate] = useState(false)

    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const [redNameEn, setRedNameEn] = useState(false)
    const [redNameRu, setRedNameRu] = useState(false)


    const [redType, setRedType] = useState(false)
    const [redConverter, setRedConverter] = useState(false)

    const [isFrequencyNeeded, setIsFrequencyNeeded] = useState(false)
    const [isGroupBlocked, setIsGroupBlocked] = useState(false)


    const [updateId, setUpdateId] = useState(null)
    const [selectorValue, setSelectorValue] = useState(null)
    const [groupID, setGroupID] = useState(null)


    const clearForm = () => {
        setNameEn("")
        setNameRu("")

        setGroupID({})
        setType({})
        setConverter({})

        setIsFrequencyNeeded(false)
        setIsGroupBlocked(false)

        setIsLoadingCreate(false)
        setIsLoadingUpdate(false)
        setIsLoadingDelete(false)

        setSelectorValue(null)
    }

    const createMode = async e => {
        e.preventDefault()

        if (isEmpty(nameEn)) {
            setRedNameEn(true)
            return toast.error(t('modes.emptyNameEn'))
        }

        if (isEmpty(nameRu)) {
            setRedNameRu(true)
            return toast.error(t('modes.emptyNameRu'))
        }

        if (type === null) {
            setRedType(true)
            return toast.error(t('modes.emptyType'))
        }

        if (converter === null) {
            setRedConverter(true)
            return toast.error(t('modes.emptyConverter'))
        }

        setIsLoadingCreate(true)
        const data = {
            nameEn,
            nameRu,
            type: type?.value,
            converter: converter?.value,
            groupID: groupID?.value,
            isFrequencyNeeded,
            isGroupBlocked
        }

        try {
            await axios.post(`${PYTHON_API_URL}/restricted/mode`, data)




            setNeedRefetchModes(true)
            toast.success(t('modes.created'))
        } catch (error) {
            handleError(error)
        }

        clearForm()

    }

    const updateMode = async () => {

        setIsLoadingUpdate(true)
        const data = {
            id: updateId,
            nameEn,
            nameRu,
            type: type?.value,
            converter: converter?.value,
            groupID: groupID?.value,
            isFrequencyNeeded,
            isGroupBlocked
        }

        try {
            await axios.patch(`${PYTHON_API_URL}/restricted/mode?id=${updateId}`, data)


            setNeedRefetchModes(true)
            toast.success(t('modes.updated'))
        } catch (error) {

            handleError(error)
        }

        clearForm()


    }



    const deleteMode = async () => {

        setIsLoadingDelete(true)

        try {
            await axios.delete(`${PYTHON_API_URL}/restricted/mode?id=${updateId}`)



            setNeedRefetchModes(true)
            toast.success(t('modes.deleted'))
        } catch (error) {
            handleError(error)
        }

        clearForm()

    }


    const ModesSelector = () =>
    (
        <Selector
            options={modes}
            isLoading={loadingModes}
            value={selectorValue}
            onChange={target => {
                setSelectorValue(target)
                setNameEn(target.nameEn)
                setNameRu(target.nameRu)
                setUpdateId(target.id)

                setIsFrequencyNeeded(target.isFrequencyNeeded)
                setIsGroupBlocked(target.isGroupBlocked)


                setType(
                    dataTypes?.[i18n.language]?.find(type => type?.value === target.type)
                )
                setConverter(
                    converters?.[i18n.language]?.find(converter => converter?.value === target.converter)
                )
                setGroupID(
                    groups?.find(group => group?.id === target.groupID)
                )
            }}
        />
    )

    const ModeSwitcher = () =>
    (
        <div className="buttons has-addons">
            <div
                className={`button ${isUpdate ? "" : "is-success"}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('modes.modeCreate')}
            </div>
            <div
                className={`button ${isUpdate ? "is-success" : ""}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('modes.modeUpdate')}
            </div>


        </div>
    )

    const FieldsUpdate =

        <>
            <div className="columns">

                <div className="column">
                    <div className="columns">
                        <div className="column">

                            <div className="field">
                                <div className="control">
                                    <input
                                        className={`input ${redNameEn ? "is-danger" : ""}`}
                                        value={nameEn}
                                        placeholder={t('modes.nameEn')}
                                        onChange={e => setNameEn(e.target.value)}

                                    />
                                </div>
                            </div>


                        </div>

                        <div className="column">

                            <div className="field">
                                <div className="control">
                                    <input
                                        className={`input ${redNameRu ? "is-danger" : ""}`}
                                        value={nameRu}
                                        placeholder={t('modes.nameRu')}
                                        onChange={e => setNameRu(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns">

                <div className="column">
                    <Selector
                        options={dataTypes?.[i18n.language]}
                        isRed={redType}
                        value={
                            type
                        }
                        placeholder={t('modes.dataType')}
                        onChange={
                            target => (
                                setType(target)
                            )
                        }
                    />
                </div>

                <div className="column">
                    <Selector
                        options={converters?.[i18n.language]}
                        isRed={redConverter}
                        value={converter}
                        placeholder={t('modes.converter')}
                        onChange={
                            target => {
                                setConverter(target)
                            }
                        }
                    />
                </div>

                <div className="column">

                    <Selector
                        options={groups}
                        isLoading={loadingGroups}
                        isClearable={true}

                        value={groupID}
                        placeholder={t('modes.group')}
                        onChange={target => (
                            setGroupID(target)
                        )}

                    />
                </div>
            </div>


            <div className="columns">


                <div className="column">
                    <div
                        className={`button is-fullwidth ${isFrequencyNeeded ? "is-success" : ""}`}
                        onClick={() => setIsFrequencyNeeded(!isFrequencyNeeded)}
                    >
                        {t('modes.isFrequencyNeeded')}
                    </div>
                </div>

                <div className="column">

                    <div
                        className={`button is-fullwidth ${isGroupBlocked ? "is-success" : ""}`}
                        onClick={() => setIsGroupBlocked(!isGroupBlocked)}
                    >
                        {t('modes.isGroupBlocked')}
                    </div>
                </div>

            </div>

        </>


    const isCreateDisabled = isEmpty(nameEn) || isEmpty(nameRu) || type === null || converter === null


    const CreateModeButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingCreate ? "is-loading" : ""} ${isCreateDisabled ? "is-static" : ""}`}
                    onClick={e => createMode(e)}
                >
                    {t('modes.create')}
                </div>
            </div>
        </div>
    )

    const UpdateModeButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingUpdate ? "is-loading" : ""} ${selectorValue === null ? "is-static" : ""}`}
                    onClick={updateMode}
                >
                    {t('modes.update')}
                </div>
            </div>

            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-danger ${isLoadingDelete ? "is-loading" : ""} ${selectorValue === null ? "is-static" : ""}`}
                    onClick={deleteMode}
                >
                    {t('modes.delete')}
                </div>
            </div>
        </div>
    )




    return <>

        <div className="box">

            <div className="label has-text-centered">
                {t("modes.title")}
            </div>

            <div className="columns">
                <div className="column">
                    {
                        isUpdate ?
                            <ModesSelector />
                            :
                            null
                    }
                </div>
                <div className="column is-narrow">
                    <ModeSwitcher />
                </div>
            </div>

            {FieldsUpdate}


            {
                isUpdate ?
                    <>
                        <UpdateModeButtons />
                    </>
                    : <CreateModeButtons />
            }


        </div>
    </>
}

export default ModesUpdateForm