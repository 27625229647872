import { useContext, useEffect, useState } from "react";
import { SeriesContext } from "../../../../context/SeriesContext";
import Selector from "../../../../helpers/Selector";
import axios from "axios";
import { PYTHON_API_URL, isEmpty } from "../../../../utils";
import i18n from "../../../../i18n";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { handleError } from "../../../../errors";

const SearchedFieldsUpdateForm = () => {

    const {
        setNeedRefetchFields,

        groups,
        loadingGroups
    } = useContext(SeriesContext);

    const { t } = useTranslation()


    const [nameEn, setNameEn] = useState("")
    const [nameRu, setNameRu] = useState("")

    const [descriptionEn, setDescriptionEn] = useState("")
    const [descriptionRu, setDescriptionRu] = useState("")

    const [nameData, setNameData] = useState("")
    const [isRequired, setIsRequired] = useState(false)
    const [isHiddenInOrder, setIsHiddenInOrder] = useState(false)
    const [isHiddenInSearch, setIsHiddenInSearch] = useState(false)
    const [isUnmerged, setIsUnmerged] = useState(false)
    const [isAlwaysSelected, setIsAlwaysSelected] = useState(false)

    const [order, setOrder] = useState(0)

    const [isUpdate, setIsUpdate] = useState(false)

    const [isLoadingCreate, setIsLoadingCreate] = useState(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [isLoadingDelete, setIsLoadingDelete] = useState(false)

    const [redNameEn, setRedNameEn] = useState(false)
    const [redNameRu, setRedNameRu] = useState(false)

    const [redDescriptionEn, setRedDescriptionEn] = useState(false)
    const [redDescriptionRu, setRedDescriptionRu] = useState(false)

    const [redNameData, setRedNameData] = useState(false)

    const [updateId, setUpdateId] = useState(null)

    const [searchFieldValue, setSearchFieldValue] = useState(null)
    const [groupValue, setGroupValue] = useState(null)


    const [updatedFields, setUpdatedFields] = useState([])
    const [loadingUpdatedFields, setLoadingUpdatedFields] = useState(false)

    const getFieldsByGroupId = async (id) => {
        setLoadingUpdatedFields(true)
        try {
            const resp = await axios.get(`${PYTHON_API_URL}/restricted/fields/list?id=${id}`)
            const { fields } = resp.data

            setUpdatedFields(fields)
        } catch (error) {
            handleError(error)
        }
        setLoadingUpdatedFields(false)
    }

    useEffect(() => {
        if (groupValue !== null) {

            getFieldsByGroupId(groupValue.value)
        }
    }, [groupValue])

    const clearForm = () => {
        setNameEn("")
        setNameRu("")
        setDescriptionEn("")
        setDescriptionRu("")
        setNameData("")
        setIsRequired(false)
        setIsHiddenInOrder(false)
        setIsHiddenInSearch(false)
        setIsUnmerged(false)
        setIsAlwaysSelected(false)

        setIsLoadingCreate(false)
        setIsLoadingUpdate(false)
        setIsLoadingDelete(false)

        setSearchFieldValue(null)
        setGroupValue(null)
    }

    const createField = async e => {
        e.preventDefault()

        if (isEmpty(nameEn)) {
            setRedNameEn(true)
            return toast.error(t('searchFields.emptyNameEn'))
        }

        if (isEmpty(nameRu)) {
            setRedNameRu(true)
            return toast.error(t('searchFields.emptyNameRu'))
        }

        if (isEmpty(descriptionEn)) {
            setRedDescriptionEn(true)
            return toast.error(t('searchFields.emptyDescriptionEn'))
        }

        if (isEmpty(descriptionRu)) {
            setRedDescriptionRu(true)
            return toast.error(t('searchFields.emptyDescriptionRu'))
        }

        if (isEmpty(nameData)) {
            setRedNameData(true)
            return toast.error(t('searchFields.emptyNameData'))
        }


        if (groupValue.length === 0) {
            return toast.error(t('searchFields.emptyGroup'))
        }

        setIsLoadingCreate(true)
        const data = {
            nameEn,
            nameRu,

            descriptionEn,
            descriptionRu,

            nameData,
            isRequired,
            isHiddenInOrder,
            isHiddenInSearch,
            isUnmerged,
            isAlwaysSelected,

            groupID: groupValue?.value
        }



        try {
            await axios.post(`${PYTHON_API_URL}/restricted/fields/create`, data)


            setNeedRefetchFields(true)
            toast.success(t('searchFields.created'))
        } catch (error) {
            handleError(error)
        }

        clearForm()

    }

    const updateField = async () => {

        setIsLoadingUpdate(true)
        const data = {
            id: updateId,
            nameEn,
            nameRu,

            descriptionEn,
            descriptionRu,

            nameData,

            order,

            isRequired,
            isHiddenInOrder,
            isHiddenInSearch,
            isUnmerged,
            isAlwaysSelected,


            groupID: groupValue?.value
        }

        try {
            await axios.patch(`${PYTHON_API_URL}/restricted/fields/update?id=${updateId}`, data)



            setNeedRefetchFields(true)
            toast.success(t('searchFields.updated'))
        } catch (error) {
            handleError(error)
        }

        clearForm()


    }

    const deleteField = async () => {

        setIsLoadingDelete(true)

        try {
            await axios.delete(`${PYTHON_API_URL}/restricted/fields/delete?id=${updateId}`)

            setNeedRefetchFields(true)
            toast.success(t('searchFields.deleted'))
        } catch (error) {
            handleError(error)
        }

        clearForm()

    }

    const FieldSelector = () =>
    (
        <Selector
            options={updatedFields?.map(
                field => (
                    {
                        value: field.id,
                        label: i18n.language === "en" ?
                            field.nameEn :
                            field.nameRu,
                        ...field
                    }
                )
            )}
            isLoading={loadingUpdatedFields}
            value={searchFieldValue}
            onChange={target => {

                setSearchFieldValue(target)
                setNameData(target.nameData)

                setNameEn(target.nameEn)
                setNameRu(target.nameRu)

                setDescriptionEn(target.descriptionEn)
                setDescriptionRu(target.descriptionRu)

                setIsRequired(target.isRequired)
                setIsHiddenInOrder(target.isHiddenInOrder)
                setIsHiddenInSearch(target.isHiddenInSearch)
                setIsAlwaysSelected(target.isAlwaysSelected)
                setIsUnmerged(target?.isUnmerged)

                setOrder(target.order)

                setUpdateId(target.id)

                const group = groups.find(group => group.id === target.groupID)


                setGroupValue({
                    value: group.id,
                    label: i18n.language === "en" ?
                        group.nameEn :
                        group.nameRu,
                    ...group
                }
                )

            }}
        />
    )

    const ModeSwitcher = () => (
        <div className="buttons has-addons">
            <div
                className={`button ${isUpdate ? "" : "is-success"}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('searchFields.modeCreate')}
            </div>
            <div
                className={`button ${isUpdate ? "is-success" : ""}`}
                onClick={() => setIsUpdate(!isUpdate)}
            >
                {t('searchFields.modeUpdate')}
            </div>


        </div>
    )







    const GroupSelector = () => (

        <Selector
            options={groups?.map(
                group => (
                    {
                        value: group.id,
                        label: i18n.language === "en" ?
                            group.nameEn :
                            group.nameRu,
                        ...group
                    }
                )
            )}
            isLoading={loadingGroups}
            value={groupValue}
            onChange={targets => {
                setGroupValue(targets)
            }}

        />

    )

    const UpdateButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingUpdate ? "is-loading" : ""} ${searchFieldValue === null ? "is-static" : ""}`}
                    onClick={updateField}
                >
                    {t('searchFields.update')}
                </div>
            </div>
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-danger ${isLoadingDelete ? "is-loading" : ""} ${searchFieldValue === null ? "is-static" : ""}`}
                    onClick={deleteField}
                >
                    {t('searchFields.delete')}
                </div>
            </div>
        </div>
    )

    const CreateButtons = () => (
        <div className="columns is-centered">
            <div className="column is-one-quarter">
                <div
                    className={`button is-fullwidth is-link ${isLoadingCreate ? "is-loading" : ""} ${isEmpty(nameEn) || isEmpty(nameRu) || isEmpty(nameData) ? "is-static" : ""}`}
                    onClick={e => createField(e)}
                >
                    {t('searchFields.create')}
                </div>
            </div>
        </div>
    )
    return <>
        <div className="box">
            <div className="label has-text-centered">
                {t("searchFields.title")}
            </div>
            <div className="columns">
                <div className="column">
                    {
                        isUpdate ?
                            <>

                                <div className="columns">
                                    <div className="column">
                                        <GroupSelector />
                                    </div>
                                    <div className="column">
                                        <FieldSelector />

                                    </div>

                                </div>
                            </>
                            :
                            null
                    }
                </div>
                <div className="column is-narrow">
                    <ModeSwitcher />
                </div>
            </div>


            <div className="columns">
                <div className="column">

                    <div className="field">
                        <div className="control">
                            <input
                                className={`input ${redNameEn ? "is-danger" : ""}`}
                                onFocus={() => setRedNameEn(false)}
                                value={nameEn}
                                placeholder={t('searchFields.nameEn')}
                                onChange={e => setNameEn(e.target.value)}

                            />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <input
                                className={`input ${redDescriptionEn ? "is-danger" : ""}`}
                                onFocus={() => setRedDescriptionEn(false)}
                                value={descriptionEn}
                                placeholder={t('searchFields.descriptionEn')}
                                onChange={e => setDescriptionEn(e.target.value)}

                            />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <input
                                className={`input ${redNameData ? "is-danger" : ""}`}
                                onFocus={() => setRedNameData(false)}
                                value={nameData}
                                placeholder={t('searchFields.nameData')}
                                onChange={e => setNameData(e.target.value)}
                            />
                        </div>
                    </div>

                </div>

                <div className="column">

                    <div className="field">
                        <div className="control">
                            <input
                                className={`input ${redNameRu ? "is-danger" : ""}`}
                                onFocus={() => setRedNameRu(false)}
                                value={nameRu}
                                type="text"
                                placeholder={t('searchFields.nameRu')}
                                onChange={e => setNameRu(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <input
                                className={`input ${redDescriptionRu ? "is-danger" : ""}`}
                                onFocus={() => setRedDescriptionRu(false)}
                                value={descriptionRu}
                                placeholder={t('searchFields.descriptionRu')}
                                onChange={e => setDescriptionRu(e.target.value)}

                            />
                        </div>
                    </div>



                </div>
            </div>

            <div className="columns">
                <div className="column">
                    <button
                        className={`button is-fullwidth ${isRequired ? "is-success" : ""}`}
                        onClick={() => setIsRequired(!isRequired)}
                    >
                        {t('searchFields.isRequired')}
                    </button>
                </div>
                <div className="column">
                    <button
                        className={`button is-fullwidth ${isAlwaysSelected ? "is-success" : ""}`}
                        onClick={() => setIsAlwaysSelected(!isAlwaysSelected)}
                    >
                        {t('searchFields.isAlwaysSelected')}
                    </button>
                </div>
                <div className="column">
                    <button
                        className={`button is-fullwidth ${isHiddenInSearch ? "is-success" : ""}`}
                        onClick={() => {
                            setIsHiddenInSearch(!isHiddenInSearch)
                            setIsHiddenInOrder(!isHiddenInSearch ? true : isHiddenInOrder)

                        }}
                    >
                        {t('searchFields.isHiddenInSearch')}
                    </button>
                </div>
                <div className="column">
                    <button
                        className={`button is-fullwidth ${isHiddenInOrder ? "is-success" : ""}`}
                        onClick={() => setIsHiddenInOrder(!isHiddenInOrder)}
                        disabled={isHiddenInSearch ? "disabled" : ""}
                    >
                        {t('searchFields.isHiddenInOrder')}
                    </button>
                </div>

                <div className="column">
                    <button
                        className={`button is-fullwidth ${!isUnmerged ? "is-success" : ""}`}
                        onClick={() => setIsUnmerged(!isUnmerged)}
                    >
                        {t('searchFields.isMerged')}
                    </button>
                </div>

            </div>



            <div className="columns is-centered">
                <div className="column is-half">
                    {isUpdate ? null : <GroupSelector />}
                </div>
            </div>

            {
                isUpdate ?
                    <UpdateButtons /> :
                    <CreateButtons />
            }


        </div >
    </>
}

export default SearchedFieldsUpdateForm