import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"

const useNews = (currentPage, setCurrentPage) => {

    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)
    const [needRefetch, setNeedRefetch] = useState(true)

    const [pageCount, setPageCount] = useState(0)


    const fetchNews = async () => {

        setLoading(true)
        try {
            const resp = await axios.get(
                `${PYTHON_API_URL}/restricted/news/list?page=${currentPage}`
            )

            const {
                news,
                pageCount,
            } = resp.data



            setPageCount(pageCount)
            setNews(news)
            setLoading(false)
        } catch (error) {

            setLoading(false)
            return handleError(error)
        }
    }

    useEffect(() => {
        if (needRefetch) {
            setCurrentPage(1)
            fetchNews()
            setNeedRefetch(false)
        }
        // eslint-disable-next-line
    }, [needRefetch])

    useEffect(() => {
        fetchNews()
        setNeedRefetch(false)
        // eslint-disable-next-line
    }, [currentPage])

    return { loading, news, setNeedRefetch, pageCount }
}

export default useNews
