import { useContext } from "react"
import { UserContext } from "../../../context/UserContext"
import DataUpdateForm from "./manipulation/update/DataUpdateForm"
import dayjs from "dayjs"
import SearchedFieldsUpdateForm from "./manipulation/SearchFieldsUpdateForm"
import GroupsUpdateForm from "./manipulation/GroupsUpdateForm"
import Filtering from "./filtering/FilteringView"
import ModesUpdateForm from "./manipulation/ModeUpdateForm"
import SelectionContextProvider from "../../../context/SelectionContext"
// import PlotView from "./visualization/PlotView"
// import LoadView from "./download/LoadView"
// import DataSelection from "./visualization/DataSelection"

var quarterOfYear = require("dayjs/plugin/quarterOfYear")
dayjs.extend(quarterOfYear)

const SeriesView = () => {

  const { user } = useContext(UserContext)

  // const [relativeDate, setRelativeDate] = useState(startDate)


  return (
    <>
      {user.userType === "admin" ? (
        <div className="columns">
          <div className="column">

            <DataUpdateForm />

            <ModesUpdateForm />

            <GroupsUpdateForm />

            <SearchedFieldsUpdateForm />

          </div>
        </div>
      ) : null}

      <SelectionContextProvider >
        <Filtering />
      </SelectionContextProvider>

      {/* {
        modeLoad ?
          <LoadView /> :
          <PlotView />
      } */}


    </>
  )
}

export default SeriesView
