import { UserContext } from "../context/UserContext"
import { Link, useRouteMatch } from "react-router-dom"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

const NavbarSections = () => {
  const {
    user: { userType },
    isSigned,
  } = useContext(UserContext)

  const { t } = useTranslation()

  const { url } = useRouteMatch()
  var sections = <></>
  switch (userType) {
    case "admin":
      sections = (
        <>
          <Link className="navbar-item" to={url + "/main"}>
            {t("navbar.main")}
          </Link>

          <Link className="navbar-item" to={url + "/news"}>
            {t("navbar.news")}
          </Link>

          <Link className="navbar-item" to={url + "/series"}>
            {t("navbar.data")}
          </Link>

          <Link className="navbar-item" to={url + "/reports"}>
            {t("navbar.reports")}
          </Link>

          <Link className="navbar-item" to={url + "/invites"}>
            {t("navbar.invites")}
          </Link>

          <Link className="navbar-item" to={url + "/users"}>
            {t("navbar.users")}
          </Link>
        </>
      )
      break
    case "ordinary":
      sections = (
        <>
          <Link className="navbar-item" to={url + "/main"}>
            {t("navbar.main")}
          </Link>

          <Link className="navbar-item" to={url + "/news"}>
            {t("navbar.news")}
          </Link>

          <Link className="navbar-item" to={url + "/series"}>
            {t("navbar.data")}
          </Link>

          <Link className="navbar-item" to={url + "/reports"}>
            {t("navbar.reports")}
          </Link>

          {/* <Link className="navbar-item" to={url + "/files"}>
            {t("navbar.files")}
          </Link> */}
        </>
      )
      break
    default:
      sections = <></>
  }

  return isSigned ? sections : <></>
}

export default NavbarSections
