import UserList from "./UserList"

const UserListView = () => {


  return (
    <>
      <div className="columns is-centered">
        <div className="column">

          <UserList />
        </div>
      </div>
    </>
  )
}

export default UserListView
