import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { errorMessage, handleError } from "../../../../../errors"
import Selector from "../../../../../helpers/Selector"
import {
  PYTHON_API_URL,
  isEmpty
} from "../../../../../utils"
import { SeriesContext } from "../../../../../context/SeriesContext"
import { UserContext } from "../../../../../context/UserContext"
import UploadTasksList from "./UploadTasksList"


const DataUpdateForm = () => {

  const [file, setFile] = useState({ name: "" })
  const [key, setKey] = useState(Math.random().toString())
  const [redFile, setRedFile] = useState(false)
  const [redFrequency, setRedFrequency] = useState(false)

  const [submitting, setSubmitting] = useState(false)

  const [uploadMode, setUploadMode] = useState('update')

  const [modeData, setModeData] = useState("")
  const [frequency, setFrequency] = useState("")

  const [frequencyDisabled, setFrequencyDisabled] = useState(false)
  const [groupDisabled, setGroupDisabled] = useState(false)


  const [redModeData, setRedModeData] = useState(false)
  const [keyModeData, setKeyModeData] = useState(Math.random().toString())

  const [groupSelectorValue, setGroupSelectorValue] = useState(null)
  const [redGroupSelector, setRedGroupSelector] = useState(false)

  const { user } = useContext(UserContext)

  const {
    groups,
    loadingGroups,

    frequencies,
    loadingFrequencies,

    modes,
    loadingModes,

    setNeedRefetchUploadTasks

  } = useContext(SeriesContext)


  const { t } = useTranslation()

  const saveData = async () => {
    setSubmitting(true)

    if (modeData.length === 0) {
      setSubmitting(false)
      setRedModeData(true)

      return toast.error(errorMessage("se:::015"))

    }

    if ((frequency.length === 0) && (!frequencyDisabled)) {
      setSubmitting(false)
      setRedFrequency(true)

      return toast.error(errorMessage("se:::005"))

    }

    if (groupSelectorValue === null) {
      setSubmitting(false)
      setRedGroupSelector(true)
      return toast.error(errorMessage("se:::016"))
    }

    // if (
    //   isEmpty(file?.name) ||
    //   (!file.name.endsWith(".xlsx") && !file.name.endsWith(".csv") && !file.name.endsWith(".zip"))) {
    //   setSubmitting(false)
    //   setRedFile(true)

    //   return toast.error(errorMessage("fi:::004"))
    // }

    try {
      const formData = new FormData()

      formData.append("file", file)
      // formData.append("frequency", frequency)
      // formData.append("groupId", groupSelectorValue?.value)
      // formData.append("type", modeData?.type)
      // formData.append("uploadMode", uploadMode)
      // formData.append("converter", modeData?.converter)

      const data = {
        userId: user?.userID,
        groupId: groupSelectorValue?.value,
        type: modeData?.type,
        mode: uploadMode,
        converter: modeData?.converter,
        taskType: "manual",
      }

      if (frequency !== "") {
        data["frequency"] = frequency
      }

      const dataStr = new URLSearchParams(data).toString()


      switch (uploadMode) {
        case "conversion":

          toast.error(errorMessage("sw:::000"))

          // const request = {
          //   headers: {
          //     "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          //   },
          //   responseType: "arraybuffer",
          // }

          // resp = await axios.post(
          //   `${API_URL}/series/updateData`,
          //   formData,
          //   request
          // )

          // fileDownload(resp.data, "converted.xlsx")
          break

        default:

          await axios.post(
            `${PYTHON_API_URL}/restricted/statistics/upload/task?${dataStr}`,

            formData
          )




          setFile({ name: "" })
          setFrequency("")
          setKey(Math.random().toString())
          setKeyModeData(Math.random().toString())
          setFrequencyDisabled(false)
          setNeedRefetchUploadTasks(true)


          setSubmitting(false)
          toast.success(t("series.dataUpdated"))

      }


    } catch (error) {
      handleError(error)
    }
    setSubmitting(false)
  }

  return (
    <>
      <div className="box is-fullwidth">

        <div className="columns is-centered">

          <div className="column">
            <label className="label has-text-centered">
              {t("series.updateData")}
            </label>
          </div>

        </div>

        <div className="columns is-left">
          <div className="column is-narrow">
            <div className="buttons has-addons is-centered">
              <button
                className={`button is-small ${uploadMode === "update" ? "is-success" : ""}`}
                onClick={() => setUploadMode("update")}
              >
                {t("series.modeUpdate")}
              </button>
              <button
                className={`button is-small ${uploadMode === "conversion" ? "is-success" : ""}`}
                onClick={() => {
                  setUploadMode("conversion")
                }}
              >
                {t("series.modeConvert")}
              </button>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control">
                <Selector
                  options={
                    modes
                  }
                  value={modeData}
                  isLoading={loadingModes}
                  placeholder={t("series.selectMode")}
                  onChange={target => {

                    setModeData(target)

                    setFrequencyDisabled(!target?.isFrequencyNeeded)
                    setGroupDisabled(target?.isGroupBlocked)

                    const foundGroup = groups.find(
                      group => group?.id === target?.groupID
                    )

                    foundGroup === undefined ? setGroupSelectorValue({}) : setGroupSelectorValue(foundGroup)


                    if (!target.isFrequencyNeeded) {
                      setFrequency("")
                      setKey(Math.random().toString())

                    }


                  }}
                  subKey={keyModeData}
                  isRed={redModeData}
                  onFocus={() => setRedModeData(false)}

                />
              </div>
            </div>

          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <Selector
                  options={Object.keys(frequencies)?.map(item => ({
                    label: item,
                    value: item
                  }))}
                  isLoading={loadingFrequencies}
                  placeholder={t("series.selectFrequency")}
                  onChange={target => setFrequency(target.value)}
                  subKey={key}
                  isDisabled={frequencyDisabled || loadingFrequencies}
                  isRed={redFrequency}
                  onFocus={() => setRedFrequency(false)}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <Selector
                  options={groups}
                  isRed={redGroupSelector}
                  placeholder={t("series.selectGroup")}
                  isLoading={loadingGroups}
                  value={groupSelectorValue}
                  onFocus={() => setRedGroupSelector(false)}
                  isDisabled={groupDisabled}
                  onChange={target => {
                    setGroupSelectorValue(target)
                  }}
                />
              </div>
            </div>
          </div>



          <div className="column is-narrow">
            <div className="field">
              <div
                className={`file is-centered is-boxed is-small ${redFile ? "is-danger" : ""
                  }`}
              >
                <label className="file-label">
                  <input
                    className="file-input "
                    type="file"
                    name="file"
                    key={key}
                    onChange={e => {
                      setFile(e.target.files[0])
                    }}
                    onFocus={() => {
                      setRedFile(false)
                    }}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fa fa-upload"></i>
                    </span>
                    <span className="file-label">{`${file?.name?.length
                      ? file?.name
                      : t("files.chooseFile")
                      }`}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-centered">

          <div className="column is-half centered">

            <button
              className={`button is-small is-fullwidth is-link ${submitting ? "is-loading" : ""
                } `}
              onClick={() => saveData()}
            >
              {t("series.loadData")}
            </button>
          </div>
        </div>
      </div>

      <UploadTasksList />

    </>
  )
}

export default DataUpdateForm
