
import { useContext } from "react"
import { SeriesContext } from "../../../../../context/SeriesContext"
import UploadTask from "./UploadTask"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../../../../helpers/LoadingSpinner"

const UploadTasksList = () => {

    const { uploadTasks, loadingUploadTasks, uploadTaskType, setUploadTaskType } = useContext(SeriesContext)

    const { t } = useTranslation()



    const UploadTaskTypeSwitcher = () =>
    (
        <div className="buttons has-addons">
            <div
                className={`button ${uploadTaskType === "manual" ? "is-success" : ""}`}
                onClick={() => setUploadTaskType("manual")}
            >
                {t('tasks.typeManual')}
            </div>
            <div
                className={`button ${uploadTaskType === "automatic" ? "is-success" : ""}`}
                onClick={() => setUploadTaskType("automatic")}
            >
                {t('tasks.typeAutomatic')}
            </div>


        </div>
    )




    return (
        <div className="box upload-tasks-box">
            <div className="columns is-centered">
                <div className="column is-narrow ">
                    <UploadTaskTypeSwitcher />
                </div>
            </div>

            {
                loadingUploadTasks ? <LoadingSpinner /> :
                    Object.keys(uploadTasks).map(id => {
                        return <UploadTask key={id} task={uploadTasks[id]} />
                    })

            }
        </div>
    )

}

export default UploadTasksList