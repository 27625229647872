import { createContext } from "react"
import useSearchFields from "./useSelection"
import useGroups from "./useGroups"
import useModes from "./useModes"
import useFrequencies from "./useFrequencies"
import useUploadTasks from "./useUploadTasks"

export const SeriesContext = createContext()

const SeriesContextProvider = ({ children }) => {

    const {
        groups,
        loading: loadingGroups,
        setNeedRefetch: setNeedRefetchGroups
    } = useGroups()

    const {
        modes,
        loading: loadingModes,
        setNeedRefetch: setNeedRefetchModes
    } = useModes()

    const {
        frequencies,
        loading: loadingFrequencies,
        setNeedRefetch: setNeedRefetchFrequencies
    } = useFrequencies()

    const {
        fields,
        loadingFields,
        setNeedRefetchFields,

        updatedFields,
        setUpdatedFields,


        fieldsValues,
        loadingFieldsValues,
        setNeedRefetchFieldsValues,

        group,
        setGroup,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

        lastUpdateDate,
        setLastUpdateDate,

        frequencyCount,

        selectedFrequencies,
        setSelectedFrequencies,

    } = useSearchFields()

    const {
        loading: loadingUploadTasks,
        tasks: uploadTasks,
        setNeedRefetch: setNeedRefetchUploadTasks,
        taskType: uploadTaskType,
        setTaskType: setUploadTaskType,
    } = useUploadTasks()


    const value = {
        group,
        setGroup,

        groups,
        loadingGroups,
        setNeedRefetchGroups,

        modes,
        loadingModes,
        setNeedRefetchModes,

        frequencies,
        loadingFrequencies,
        setNeedRefetchFrequencies,

        fields,
        loadingFields,
        setNeedRefetchFields,

        updatedFields,
        setUpdatedFields,

        fieldsValues,
        loadingFieldsValues,
        setNeedRefetchFieldsValues,

        selectedFields,
        setSelectedFields,

        selectedFieldsOrder,
        setSelectedFieldsOrder,

        lastUpdateDate,
        setLastUpdateDate,

        frequencyCount,


        selectedFrequencies,
        setSelectedFrequencies,

        loadingUploadTasks,
        uploadTasks,
        setNeedRefetchUploadTasks,

        uploadTaskType,
        setUploadTaskType,
    }

    return (
        <SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>
    )
}

export default SeriesContextProvider
