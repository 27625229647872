import axios from "axios"
import { useEffect, useState } from "react"
import { PYTHON_API_URL } from "../utils"
import { handleError } from "../errors"

const useInvites = (userType, isActive) => {
  const [invites, setInvites] = useState([])
  const [loading, setLoading] = useState(false)
  const [needRefetch, setNeedRefetch] = useState(true)

  const fetchInvites = async () => {
    try {
      const resp = await axios.get(
        `${PYTHON_API_URL}/restricted/invite/list?userType=${userType}&isActive=${isActive}`
      )

      const { invites } = resp.data
      setInvites(invites)
      setLoading(false)

    } catch (error) {
      setLoading(false)

      return handleError(error)

    }
  }

  useEffect(() => {
    setLoading(true)
    fetchInvites()

    // eslint-disable-next-line
  }, [userType, isActive])

  useEffect(() => {
    setLoading(true)
    fetchInvites()
    setNeedRefetch(false)
    // eslint-disable-next-line
  }, [needRefetch])

  return { loading, invites, setNeedRefetch }
}

export default useInvites
