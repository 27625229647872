
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom"
import UserListView from "./UserListView"

const UserView = () => {
  const { path, url } = useRouteMatch()


  return (
    <>
      <div className="columns">
        <div className="column">
          <Switch>
            <Route exact path={path}>
              <Redirect to={url + "/list"} />
            </Route>
            <Route path={path + "/list"}>
              <UserListView />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default UserView
