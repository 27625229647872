import { createContext, useState } from "react"
import useNews from "./useNews"

export const NewsContext = createContext()

const NewsContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const {
        news,
        loading: newsLoading,
        setNeedRefetch: setNeedRefetchNews,
        pageCount
    } = useNews(currentPage, setCurrentPage)

    const value = {
        news,
        newsLoading,
        setNeedRefetchNews,
        pageCount,
        currentPage,
        setCurrentPage,
    }

    return (
        <NewsContext.Provider value={value}>
            {children}
        </NewsContext.Provider>
    )
}

export default NewsContextProvider
