import { useContext } from "react"
import i18n from "../../../i18n"
import { UserContext } from "../../../context/UserContext"
import axios from "axios"
import { PYTHON_API_URL } from "../../../utils"
import { NewsContext } from "../../../context/NewsContext"
import { handleError } from "../../../errors"

const News = ({ message }) => {

    const { locales, creationDate } = message

    const {
        user: { userType }
    } = useContext(UserContext)

    const { setNeedRefetchNews } = useContext(NewsContext)

    // send id to delete message
    const deleteNews = async id => {

        try {
            await axios.delete(
                `${PYTHON_API_URL}/restricted/news?id=${id}`
            )

            setNeedRefetchNews(true)
        } catch (error) {
            return handleError(error)

        }

    }



    return (

        <article className="message is-success">
            <div className="message-header">
                <p>{new Date(creationDate)
                    .toLocaleDateString("ru-RU")}</p>

                {
                    userType === "admin"
                        ? <button
                            class="delete"
                            aria-label="delete"
                            onClick={() => deleteNews(message.id)
                            }
                        />
                        : null}
            </div>

            <div className="message-body">
                <div className="content">

                    <div dangerouslySetInnerHTML={{ __html: locales?.[i18n?.language] }} />
                </div>
            </div>
        </article>
    )
}

export default News  