import { useLocation } from "react-router"
import dayjs from "dayjs"

// export const messageToToasts = message => {


//   const arrayError = message.trim().split('@')


//   const arrayMessages = errorMessage(arrayError[0])?.split("\n")


//   if (arrayError.length === 2) {
//     arrayMessages[0] = arrayMessages[0] + "\n" + arrayError[arrayError.length - 1]
//   }


//   if (arrayMessages.length === 1) return toast.error(arrayMessages[0])
//   else
//     return arrayMessages.slice(0, -1).forEach(mes => {
//       toast.error(mes)
//     })
// }

export const API_URL = process.env.REACT_APP_API_URL
export const PYTHON_API_URL = process.env.REACT_APP_PYTHON_API_URL

// export const API_URL = "http://localhost:3070"

export const isStrongPwd = password => {
  var regExp = /(?=.*\d)(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ]).{8,}/

  var validPassword = regExp.test(password)

  return validPassword
}

export const isEmpty = field => {
  var regExp = /\S/
  var validField = regExp.test(field)

  return !validField
}

export const nilObjectID = "000000000000000000000000"

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const userTypesOptions = {
  en: [
    { label: "User", value: "ordinary" },
    { label: "Administrator", value: "admin" },
  ],

  ru: [
    { label: "Пользователь", value: "ordinary" },
    { label: "Администратор", value: "admin" },
  ],
}

export const inviteStatusOptions = {
  en: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],

  ru: [
    { label: "Активные", value: true },
    { label: "Неактивные", value: false },
  ],
}


export const frequencyToLabel = {
  en: {
    "D": "Daily",
    "B": "Business days",
    "W": "Weekly",
    "M": "Monthly",
    "Q": "Quarterly",
    "Y": "Yearly",
  },

  ru: {
    "D": "Дневные",
    "B": "Рабочие дни",
    "W": "Недельные",
    "M": "Месячные",
    "Q": "Квартальные",
    "Y": "Годовые",
  },
}

export const dataTypes = {
  en: [
    { label: "Statistics", value: "statistics" },
    { label: "Forecasts", value: "forecasts" }
  ],
  ru: [
    { label: "Statistics", value: "statistics" },
    { label: "Forecasts", value: "forecasts" }
  ]
}

export const converters = {
  en: [
    { label: "Cru Fertilizer Week", value: "cru_fertilizer_week" },
    { label: "Fertecon general", value: "fertecon_general" },
    { label: "Fertecon potassium", value: "fertecon_potassium" },
    { label: "Argus", value: "argus" },
    { label: "ICIS", value: "icis" },
    { label: "ICIS Industrial", value: "icis_industrial" },
    { label: "ICIS HTML", value: "icis_html" },
    { label: "Investing", value: "investing" },
    { label: "Profercy", value: "profercy" },
    { label: "Profercy PDF", value: "profercy_pdf" },
    { label: "Coal", value: "coal" },
    { label: "Afriqom", value: "afriqom" },
    { label: "Default", value: "default" },
    { label: "Infofert", value: "infofert" },
    { label: "Metals", value: "metals" },
    { label: "CFR Indicatives", value: "cfr_indicatives" },

  ],
  ru: [
    { label: "Cru Fertilizer Week", value: "cru_fertilizer_week" },
    { label: "Фертекон общий", value: "fertecon_general" },
    { label: "Фертекон калий", value: "fertecon_potassium" },
    { label: "Аргус", value: "argus" },
    { label: "ICIS", value: "icis" },
    { label: "ICIS Индустриальный", value: "icis_industrial" },
    { label: "ICIS HTML", value: "icis_html" },
    { label: "Инвестинг", value: "investing" },
    { label: "Profercy", value: "profercy" },
    { label: "Profercy PDF", value: "profercy_pdf" },
    { label: "Уголь", value: "coal" },
    { label: "Afriqom", value: "afriqom" },
    { label: "Стандартный", value: "default" },
    { label: "Инфоферт", value: "infofert" },
    { label: "Металлы", value: "metals" },
    { label: "CFR Индикативы", value: "cfr_indicatives" },
  ]
}

export const showLastOptions = {
  en: [
    { label: "Last week", value: "w" },
    { label: "Last month", value: "M" },
    { label: "Last quarter", value: "Q" },
    { label: "Last year", value: "y" },
    { label: "Last 5 years", value: "5y" },
    { label: "Last 10 years", value: "10y" },
    { label: "All", value: "A" },
  ],

  ru: [
    { label: "Последняя неделя", value: "w" },
    { label: "Последний месяц", value: "M" },
    { label: "Последний квартал", value: "Q" },
    { label: "Последний год", value: "y" },
    { label: "Последние 5 лет", value: "5y" },
    { label: "Последние 10 лет", value: "10y" },
    { label: "Все", value: "A" },
  ],
}

export const dateColumnsOptions = {
  en: [
    { label: "Date short", value: "ShortDate" },
    { label: "Start date", value: "StartDate" },
    { label: "End date", value: "EndDate" },
    { label: "Aligned publication date", value: "AlignedPublicationDate" },
    { label: "Publication date", value: "PublicationDate" },
  ],
  ru: [
    { label: "Дата короткая", value: "ShortDate" },
    { label: "Дата начала", value: "StartDate" },
    { label: "Дата окончания", value: "EndDate" },
    { label: "Приведённая дата публикации", value: "AlignedPublicationDate" },
    { label: "Дата публикации", value: "PublicationDate" },
  ],
}


export const reportsOptions = {
  en: [
    { label: "Commodity", value: "commodity" },

  ],
  ru: [
    { label: "Товары", value: "commodity" },

  ],
}

export const chartColors = [
  "rgb(54, 162, 235)",
  "rgb(75, 192, 192)",
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(153, 102, 255)",
]

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const freqToShowlast = {
  Y: 4,
  Q: 5,
  M: 6,
  W: 7,
  B: 7,
  D: 7,
  "": 7,
}

export const freqToOrder = {
  Y: 6,
  Q: 5,
  M: 4,
  W: 3,
  B: 2,
  D: 1,
}

const maxPointSize = 5
const meanPointSize = 3
const minPointSize = 2

export const freqShowLastToRadius = {
  Y: maxPointSize,
  Q: maxPointSize,
  M: meanPointSize,
  W: meanPointSize,
  B: minPointSize,
  D: minPointSize,
}



export const rootParent = {
  nameRu: "Корневая",
  nameEn: "Root",
  value: nilObjectID,
}

export const maxSelectedOptions = 5

export const languageOptions = {
  en: [
    { label: "English", value: "en" },
    { label: "Russian", value: "ru" },
  ],

  ru: [
    { label: "Английский", value: "en" },
    { label: "Русский", value: "ru" },
  ],
}

export const forecastsModeOptions = {
  en: [
    { label: "By selector", value: "" },
    // { label: "No", value: "NO" },
    // { label: "Current", value: "CURRENT" },
    { label: "All", value: "ALL" },
  ],

  ru: [
    { label: "Через селектор", value: "" },
    // { label: "Никакие", value: "NO" },
    // { label: "Актуальные", value: "CURRENT" },
    { label: "Все", value: "ALL" },
  ],
}



export const updateForecastsModeOptions = {
  en: [
    { label: "Custom", value: "default" },
    { label: "CRU Fertilizer Week", value: "cru" },
    { label: "Bank of Russia survey", value: "cb" }
  ],

  ru: [
    { label: "Пользовательский", value: "default" },
    { label: "CRU Fertilizer Week", value: "cru" },
    { label: "Опрос Банка России", value: "cb" }
  ],
}

// check date is between two dates

export const isBetween = (date, from_date, to_date) => {
  if (from_date && to_date) {
    return date >= from_date && date <= to_date
  } else {
    if (from_date) {
      return date >= from_date
    } else if (to_date) {
      return date <= to_date
    } else {
      return true
    }
  }
}

export const seriesToNode = (series) => {
  const label = series.name
    ? `${series.columnName} - ${series.name}`
    : series.columnName
  return {
    id: series.id,
    columnName: series.columnName,
    parentID: series.parentID,
    label,
    value: series.id,
    frequency: series.frequency,
    nameRu: label,
    nameEn: label,
    type: "series",
  }
}



export const forecastToNode = (forecast) => {

  const label = `${forecast.columnName} - ${forecast.originText}`
  return {
    id: forecast.id,
    label: label,
    value: forecast.id,
    frequency: forecast.frequency,
    origin: new Date(forecast.origin),
    parentID: forecast.source,
    nameRu: label,
    nameEn: label,
    type: "series",
  }

}

export const parseLogs = (logs) => {

  var emails = []
  var dates = []
  var messages = []

  for (var i = 0; i < logs.length; i++) {
    const log = logs[i]
    emails.push(log.author)
    dates.push(dayjs(log.date).format("DD/MM/YY - HH:mm"))
    messages.push(log.text)
  }


  return [emails, dates, messages]
}

export const outputFormatOptions = {
  en: [
    { label: "Standard", value: "standard" },
    { label: "Flat (csv)", value: "flat" },
    // { label: "Pivot", value: "pivot" }
  ],

  ru: [
    { label: "Стандартный", value: "standard" },
    { label: "Плоский (csv)", value: "flat" },
    // { label: "Сводный", value: "pivot" }
  ],
}


export const messageTypeOptions = {
  en: [
    { label: "Message", value: "message" },
    { label: "Mail", value: "mail" },
    { label: "Message with mail", value: "message-with-mail" },
  ],

  ru: [
    { label: "Сообщение", value: "message" },
    { label: "Email", value: "mail" },
    { label: "Сообщение и email", value: "message-with-mail" },
  ],
}

export const isEmptyEditor = state => {
  return !state.getCurrentContent().hasText()
}

