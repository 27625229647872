import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { errorMessage, handleError } from "../errors"
import LoadingSpinner from "../helpers/LoadingSpinner"
import {
  isStrongPwd,
  isEmpty,
  useQuery,
  validateEmail,
  PYTHON_API_URL
} from "../utils"
import PasswordRules from "./PasswordRules"

const SignUp = ({ userType }) => {
  const query = useQuery()
  const history = useHistory()

  const code = query.get("code")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  const [codeValid, setCodeValid] = useState(null)
  const [submittingCheck, setSubmittingCheck] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [company, setCompany] = useState("")
  const [fullName, setFullName] = useState("")
  const [position, setPosition] = useState("")
  const [bossFullName, setBossFullName] = useState("")
  const [bossEmail, setBossEmail] = useState("")
  const [bossPosition, setBossPosition] = useState("")

  const [redEmail, setRedEmail] = useState(false)
  const [redPassword, setRedPassword] = useState(false)
  const [redPasswordRepeat, setRedPasswordRepeat] = useState(false)
  const [redCompany, setRedCompany] = useState(false)
  const [redFullName, setRedFullName] = useState(false)
  const [redPosition, setRedPosition] = useState(false)
  const [redBossFullName, setRedBossFullName] = useState(false)
  const [redBossEmail, setRedBossEmail] = useState(false)
  const [redBossPosition, setRedBossPosition] = useState(false)

  const { t } = useTranslation()

  const signUp = async event => {
    event.preventDefault()

    const isEmptyConditions = [
      isEmpty(fullName),
      isEmpty(company),
      isEmpty(position),
      isEmpty(bossFullName),
      isEmpty(bossEmail),
      isEmpty(bossPosition),

    ]

    if (isEmptyConditions.includes(true)) {
      setRedFullName(isEmpty(fullName))
      setRedCompany(isEmpty(company))
      setRedPosition(isEmpty(position))
      setRedBossFullName(isEmpty(bossFullName))
      setRedBossEmail(isEmpty(bossEmail))
      setRedBossPosition(isEmpty(bossPosition))

      return toast.error(errorMessage("su:::004"))
    }

    if (isEmpty(fullName)) {
      setRedFullName(true)
      return toast.error(errorMessage("su:::004"))
    }



    if (password === "") {
      setRedPassword(true)
      return toast.error(errorMessage("su:::000"))
    }

    if (passwordRepeat === "") {
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::001"))
    }

    if (!isStrongPwd(password)) {
      setRedPassword(true)
      setRedPasswordRepeat(true)

      return toast.error(errorMessage("su:::002"))
    }

    if (password !== passwordRepeat) {
      setRedPassword(true)
      setRedPasswordRepeat(true)
      return toast.error(errorMessage("su:::003"))
    }

    if (!validateEmail(bossEmail)) {
      setRedBossEmail(true)
      return toast.error(errorMessage("su:::005"))

    }

    setSubmittingForm(true)

    const data = {
      fullName,
      email,
      password,
      passwordRepeat,
      userType,
      code,
      usedInvite: code,
      company,
      position,
      bossFullName,
      bossEmail,
      bossPosition
    }

    try {

      await axios.post(`${PYTHON_API_URL}/unrestricted/auth/signup`, data)

      setPassword("")
      setPasswordRepeat("")
      setSubmittingForm(false)

    } catch (error) {
      setSubmittingForm(false)

      handleError(error)


    }


    switch (userType) {
      case "admin":
        history.push(`/auth/admin`)
        break
      default:
        history.push(`/auth`)
    }

    return toast.success(t("signUp.success"))
  }

  const checkCode = async () => {
    setSubmittingCheck(true)

    const stringUserType = String(userType)

    const data = {
      code,
      userType: stringUserType,
    }
    try {

      const query = new URLSearchParams(data).toString()

      const resp = await axios.get(`${PYTHON_API_URL}/unrestricted/auth/check?${query}`, data)
      const { email } = resp.data

      setEmail(email)
      setCodeValid(true)
      setSubmittingCheck(false)

    } catch (error) {
      setSubmittingCheck(false)
      setCodeValid(false)

      handleError(error)

    }
  }

  useEffect(() => {
    checkCode()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-4">
          {submittingCheck || codeValid === null ? (
            <LoadingSpinner />
          ) : !codeValid ? (
            <div className="box">
              <h2>{t("signUp.outdated")}</h2>
            </div>
          ) : (
            <form className="box">



              <div className="field">
                <label className="label has-text-left">{t("signUp.email")}</label>
                <div className="control has-icons-left">
                  <input
                    className={`input ${redEmail ? "is-danger" : ""}`}
                    type="email"
                    value={email}
                    placeholder=""
                    disabled="disabled"
                    onChange={e => setEmail(e.target.value)}
                    onFocus={() => setRedEmail(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.fullName")}
                </label>
                <div className="control">
                  <input
                    className={`input ${redFullName ? "is-danger" : ""}`}
                    type="text"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    onFocus={() => setRedFullName(false)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">{t("signUp.company")}</label>
                <div className="control">
                  <input
                    className={`input ${redCompany ? "is-danger" : ""}`}
                    type="text"
                    value={company}

                    onChange={e => setCompany(e.target.value)}
                    onFocus={() => setRedCompany(false)}
                  />
                </div>
              </div>


              <div className="field">
                <label className="label has-text-left">{t("signUp.position")}</label>
                <div className="control">
                  <input
                    className={`input ${redPosition ? "is-danger" : ""}`}
                    type="text"
                    value={position}
                    onChange={e => setPosition(e.target.value)}
                    onFocus={() => setRedPosition(false)}
                  />
                </div>
              </div>


              <div className="field">
                <label className="label has-text-left">{t("signUp.bossFullName")}</label>
                <div className="control">
                  <input
                    className={`input ${redBossFullName ? "is-danger" : ""}`}
                    type="text"
                    value={bossFullName}
                    placeholder=""
                    onChange={e => setBossFullName(e.target.value)}
                    onFocus={() => setRedBossFullName(false)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">{t("signUp.bossEmail")}</label>
                <div className="control has-icons-left">
                  <input
                    className={`input ${redBossEmail ? "is-danger" : ""}`}
                    type="email"
                    value={bossEmail}
                    placeholder=""
                    onChange={e => setBossEmail(e.target.value)}
                    onFocus={() => setRedBossEmail(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </div>
              </div>



              <div className="field">
                <label className="label has-text-left">{t("signUp.bossPosition")}</label>
                <div className="control">
                  <input
                    className={`input ${redBossPosition ? "is-danger" : ""}`}
                    type="text"
                    value={bossPosition}
                    placeholder=""
                    onChange={e => setBossPosition(e.target.value)}
                    onFocus={() => setRedBossPosition(false)}
                  />
                </div>
              </div>


              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.password")}
                </label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${redPassword ? "is-danger" : ""}`}
                    type="password"
                    value={password}
                    placeholder="********"
                    disabled={!codeValid ? "disabled" : ""}
                    onChange={e => setPassword(e.target.value)}
                    onFocus={e => setRedPassword(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <div className="field">
                <label className="label has-text-left">
                  {t("signUp.passwordRep")}
                </label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${redPasswordRepeat ? "is-danger" : ""}`}
                    type="password"
                    value={passwordRepeat}
                    placeholder="********"
                    required
                    onChange={e => setPasswordRepeat(e.target.value)}
                    onFocus={e => setRedPasswordRepeat(false)}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                  </span>
                </div>
              </div>

              <PasswordRules />

              <div className="field">
                <div className="control">
                  <div
                    className={`button ${submittingForm ? "is-loading" : ""
                      } is-dark`}
                    type="submit"
                    onClick={e => signUp(e)}
                  >
                    {t("signUp.signUp")}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default SignUp
