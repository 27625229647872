import { createContext, useState } from "react"
import useInvites from "./useInvites"

export const InvitesContext = createContext()

const InvitesContextProvider = ({ children }) => {
  const [userType, setUserType] = useState("ordinary")
  const [isActive, setIsActive] = useState(true)

  const { invites, loading, setNeedRefetch } = useInvites(userType, isActive)

  const value = {
    invites,
    loading,
    userType,
    setUserType,
    isActive,
    setIsActive,
    setNeedRefetch,
  }

  return (
    <InvitesContext.Provider value={value}>{children}</InvitesContext.Provider>
  )
}

export default InvitesContextProvider
