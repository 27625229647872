import { toast } from "react-toastify"
import i18n from "./i18n"

const ERRORS = {
  ru: {

    "server:::000": "Ошибка сервера. Перезагрузите страницу или обратитесь к администратору",

    "data:::000": "Ошибка обработки данных",
    "data:::001": "Противоречия в данных",
    "data:::002": "Данные в файле не найдены",

    "authorization:::000": "Доступ запрещён",
    "authorization:::001": "Недостаточно прав",
    "authorization:::002": "Неправильное имя пользователя или пароль",
    "authorization:::003": "Пользователь уже существует",
    "authorization:::004": "Приглашение некорректно или истекло",
    "authorization:::005": "Некорректный токен",
    "authorization:::006": "Пользователь не существует",

    "mail:::000": "Ошибка почтового клиента",
    "mail:::001": "Часть писем не была доставлена",

    "invite:::000": "IОшибка приглашения",
    "invite:::001": "Приглашение для этого пользователя уже существует",

    "file:::000": "Файл некорректен. Обратитесь к администратору.",
    "file:::001": "Файл слишком велик",
    "file:::002": "Файл не найден",

    "user:::000": "Ошибка пользователя",

    "group:::000": "Ошибка группы",

    "fields:::001": "Поле поиска уже существует. Проверьте название на обоих языках",

    "sw:::000":
      "Ошибка сервера. Перезагрузите страницу или обратитесь к администратору.",
    "sw:::001": "Ошибка базы данных.",
    "sw:::002": "Неправильный формат запроса.",
    "sw:::003": "Недостаточно прав для данного действия.",
    "sw:::004": "Ошибка почтового клиента.",
    "sw:::005": "Ошибка обработки файла.",
    "sw:::006": "Ошибка идентификатора.",

    "auth:::000": "Неверный email.",
    "auth:::001": "Пароль и повтор пароля не совпадают.",
    "auth:::002": "Пользователь с таким логином не найден.",
    "auth:::003": "Пароль неверен.",
    "auth:::004": "Приглашение для такого email уже существует.",
    "auth:::005":
      "Пользователь с таким именем и типом уже существует. Пожалуйста, обратитесь к администратору.",
    "auth:::006":
      "Ваш инвайт не найден. Пожалуйста, обратитесь к админимтратору.",
    "auth:::007": "Доступ запрещён.",
    "auth:::008": "Ссылка смены пароля уже была использована либо устарела.",
    "auth:::009": "Введите email.",
    "auth:::010": "Введите пароль.",
    "auth:::011": "Email не является валидным.",
    "auth:::012": "Выберите язык приглашения.",

    "su:::000": "Введите пароль.",
    "su:::001": "Введите повтор пароля.",
    "su:::002": "Слишком простой пароль.",
    "su:::003": "Пароли не совпадают.",
    "su:::004": "Заполните все обязательные поля.",
    "su:::005": "Адрес электронной почты некорректен.",

    "fi:::000": "Файл не найден. Обратитесь к администратору.",
    "fi:::001": "Доступ запрещён. Обратитесь к администратору.",
    "fi:::002": "Название файла должно быть непустым.",
    "fi:::003": "Загрузите файл.",
    "fi:::004": "Загрузите .xlsx файл.",
    "fi:::005": "Некорректный .xlsx файл.",

    "fields:::001": "Поле поиска с таким названием уже существует. Проверьте названия на обоих языках.",

    "ca:::000":
      "Такая категория уже существует. Проверьте названия на обоих языках.",
    "ca:::001": "Введите название категории.",
    "ca:::002": "Изменения отсутствуют.",
    "ca:::003": "Выберите категорию.",
    "ca:::004": "Корневая категория не может быть удалена.",

    "se:::000":
      "Частотности загруженных и имеющихся в базе данных не совпадают.",
    "se:::001": "Неверный формат даты.",
    "se:::002":
      "Временной ряд не найден в базе. Пожалуйста, обратитесь к администратору.",
    "se:::003": "Пожалуйста, укажите частоту.",
    "se:::004": "Не выбрано ни одного ряда.",
    "se:::005": "Не выбрана частота.",
    "se:::006": "Формат ввода некорректен.",
    "se:::007": "Не указано ни одного идентификатора.",
    "se:::008": "Введена некорректная частота. Сверьтесь с примером.",
    "se:::009": "Некорректный формат даты. Сверьтесь с примером.",
    "se:::010": "Некорректый параметр выбора прогнозов. Сверьтесь с примером.",
    "se:::011":
      "Некоторые идентификаторы не распознаны. Проверьте введённые данные.",
    "se:::012":
      "Дата начала периода хронологически позже даты конца. Проверьте введённые данные.",
    "se:::013": "Неверный режим загрузки временных рядов.",
    "se:::014": "Неверный режим загрузки прогнозов.",
    "se:::015": "Выберите режим.",
    "se:::016": "Некоторые поля были изменены.",
    "se:::017": "Категория не входит в список заданных.",
    "se:::018": "Формат Forecast origin некорректен. Проверьте, что соответствующая колонка имеет тип 'Текст' и проверьте формат на главной странице.",
    "se:::019": "Некоторые страницы книги пропущены.",
    "se:::020": "Формат даты некорректен для выбранной частоты.",
    "se:::021": "Формат некоторых колонок некорректен.",
    "se:::022": "Для загруженного прогноза отсутствует статистика.",
    "se:::023": "В поле Forecast origin присутствуют пропуски. Проверьте, что все ячейки в Excel имеют одинаковый формат.",
    "se:::024": "В базе данных отсутствуют данные об удобрениях. Пожалуйста, обратитесь к администратору.",
    "se:::025": "В базе данных отсутствует главный индекс. Пожалуйста, обратитесь к администратору.",
    "se:::026": "Значения ряда в базе данных отсортированы неверно.",
    "se:::027": "Часть данных не была отображена. Обратитесь к администратору.",
    "se:::028": "Выберите не менее одной колонки дат.",
    "se:::029": "При плоском формате можно выбрать только одну частоту",
    "se:::030": "Выгрузка более 5000 рядов временно запрещена.",

    "ex:::001": "Необходимо указать не менее одного идентификатора.",

    "sf:::000": "Поле поиска уже существует.",

    "md:::000": "Режим с таким именем уже существует",

    "rp:::000": "Выберите отчёт",



  },
  en: {
    "server:::000": "Server error, try to reload page or contact administrator",

    "data:::000": "Data processing error",
    "data:::001": "Collisions in data were found",
    "data:::002": "No data found in file",

    "authorization:::000": "Access denied",
    "authorization:::001": "Insufficient permissions",
    "authorization:::002": "Incorrect username or password",
    "authorization:::003": "Such user is already exists",
    "authorization:::004": "Invite is invalid or expired",
    "authorization:::005": "Invalid token",
    "authorization:::006": "User doesn't exist",

    "mail:::000": "Mail client error",
    "mail:::001": "Some messages were not delivered",

    "invite:::000": "Invite error",
    "invite:::001": "Invite for such email is already exists",

    "file:::000": "File is incorrect. Contact administrator",
    "file:::001": "File is too large",
    "file:::002": "File not found",

    "user:::000": "User error",

    "group:::000": "Group error",

    "fields:::001": "A search field with the same name already exists. Check the title in both languages.",

    "sw:::000": "Server error. Try to reload page or contact administrator.",
    "sw:::001": "Database error.",
    "sw:::002": "Invalid request fromat.",
    "sw:::003": "Access denied.",
    "sw:::004": "Mail client error.",
    "sw:::005": "File processing error.",
    "sw:::006": "Identifier error.",


    "auth:::000": "Incorrect email.",
    "auth:::001": "Password and password repeat are not equal.",
    "auth:::002": "No user with such login.",
    "auth:::003": "Password is incorrect.",
    "auth:::004": "Invite for such email is already exists.",
    "auth:::005":
      "User with such email and user type is already exists. Please, contact system administrator.",
    "auth:::006":
      "No invite or more than one invite for that code. Please, check login type and contact administrator.",
    "auth:::007": "Access denied.",
    "auth:::008": "Link is outdated or has already used.",
    "auth:::009": "Please, insert email.",
    "auth:::010": "Please, insert password.",
    "auth:::011": "Invalid email.",
    "auth:::012": "Please, choose language.",

    "su:::000": "Please, insert password.",
    "su:::001": "Please, insert password repeat.",
    "su:::002": "Password is not strong enough.",
    "su:::003": "Passwords are not equal.",
    "su:::004": "Please, fill all required fields.",
    "su:::005": "Email address is invalid.",


    "fi:::000": "File was not found. Please, contact administrator.",
    "fi:::001": "Access denied. Please, contact administrator.",
    "fi:::002": "File name must be non-empty.",
    "fi:::003": "Please, upload file.",
    "fi:::004": "Please, upload .xlsx file.",
    "fi:::005": "Invalid .xlsx file.",

    "ca:::000":
      "Such category is already exists. Check all titles in both languages.",
    "ca:::001": "Please, insert category title.",
    "ca:::002": "No changes done.",
    "ca:::003": "Please, choose category.",
    "ca:::004": "Root category can't be deleted.",

    "se:::000": "Frequency in loaded data and database are not equal.",
    "se:::001": "Invalid date format.",
    "se:::002":
      "Time series not found in database. Please, contact administrator.",
    "se:::003": "Please, select frequency.",
    "se:::004": "No series selected.",
    "se:::005": "Frequency not selected.",
    "se:::006": "Incorrect input format.",
    "se:::007": "No identifiers provided.",
    "se:::008": "Incorrect frequency provided. Check the example.",
    "se:::009": "Incorrect date format. Check the example.",
    "se:::010": "Incorrect forecasts option. Check the example.",
    "se:::011": "Some identifiers are not recognized. Check your input.",
    "se:::012": "First date is later than second. Check your input.",
    "se:::013": "Invalid mode for time series uploading.",
    "se:::014": "Invalid mode for forecasts uploading.",
    "se:::015": "Choose mode.",
    "se:::016": "Some fields have been changed.",
    "se:::017": "Category is not pre-defined.",
    "se:::018": "Origin format is invalid. Check that this column is 'Text' type in excel. Also check formats on main page.",
    "se:::019": "Some sheets are missed in file.",
    "se:::020": "Date format is invalid for chosen frequency.",
    "se:::021": "Some column names are invalid.",
    "se:::022": "There is no statistics for uploaded forecast.",
    "se:::023": "There are missing values in origin. Check that all cells in excel have the same data format.",
    "se:::024": "There is no data about fertilizers in database. Please, contact administrator.",
    "se:::025": "There is no main index in database. Please, contact administrator.",
    "se:::026": "Values of series are sorted incorrectly.",
    "se:::027": "Some data is not displayed. Please, contact administrator.",
    "se:::028": "Choose at least one date column.",
    "se:::029": "Flat format allows only one frequency to be selected",
    "se:::030": "Downloading more than 5000 series is temporarily prohibited.",


    "ex:::001": "At least one identifier required.",

    "sf:::000": "Search field with such names already exists.",

    "md:::000": "Mode with such names already exists.",

    "rp:::000": "Choose report",
  },
}

export const errorMessage = (code) => {
  if (code in ERRORS?.[i18n.language] & code !== "") {
    return ERRORS?.[i18n?.language][code]
  } else {
    return ERRORS?.[i18n.language]?.["server:::000"]
  }
}

export const handleError = error => {

  if (error?.response) {
    const { message } = error?.response?.data
    return toast.error(errorMessage(message))
  } else {
    console.log(error)
    return toast.error(errorMessage())
  }

}
